@import "../var";

.no-wrap {
  @media (max-width: 578px) {
    &::-webkit-scrollbar {
      display: none !important;
    }
  }
}

.content-menu,
.content-menu-evaluation {
  ul {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 62px;
    position: relative;
    width: 100%;
    z-index: 1;

    li {
      display: inline-flex;
      margin-inline-end: 2.75rem;
      > a,
      > span {
        width: max-content;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $transition;
        color: $footer-text-font-color;
        line-height: 2;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          z-index: -1;
          bottom: 0;
          right: 0;
          width: 0;
          height: 100%;
          background-color: #fff;
          border-radius: 6px;
          transition: $transition;
        }
        &:hover,
        &.active {
          transition: $transition;
          color: $new-primary-blue !important;
          //font-weight: bold !important;
          font-weight: 500;
          //border-bottom: solid 3px $new-primary-blue;
          &:after {
            width: 100%;
            transition: $transition;
          }
        }
      }
    }
  }
}

.content-menu {
  nav {
    ul {
      li {
        a {
          &:after {
            //border-bottom: 4px $new-primary-blue solid;
            border-radius: 6px;
          }
        }
      }
    }
  }
}

.content-menu-evaluation {
  ul {
    li {
      > a,
      > span {
        &:after {
          content: "";
          position: absolute;
          z-index: -1;
          border-radius: 6px;
          transition: $transition;
          bottom: 0;
          right: 0;
          width: 0;
          height: 4px;
          background-color: $new-primary-blue;
        }
      }
    }
  }
}

@media (min-width: 1200px) {
  .content-menu-evaluation {
    ul {
      li {
        margin-left: 50px;
      }
    }
  }
  .evaluation-table {
    .five {
      //max-width: 502px;
      min-width: 502px;
    }
    .four,
    .three {
      max-width: 200px;
      min-width: 200px;
    }

    .tow {
      max-width: 240px;
      min-width: 240px;
    }
  }
}

@media (max-width: 768px) {
  .content-menu {
    nav {
      ul {
        li {
          padding-left: 25px;
        }
      }
    }
  }
}

@media (max-width: 578px) {
  .content-menu {
    nav {
      width: 100%;
      padding: 0 15px;
      ul {
        flex-wrap: wrap;
        li {
          width: 100%;
          padding: 0;
          a {
            width: 100%;
            text-align: center;
            //&:before{
            //    content: '';
            //    position: absolute;
            //    bottom: 0;
            //    right:  0;
            //    width: 100%;
            //    height: 4px;
            //    background-color: $color-gray;
            //    transition: $transition;
            //     z-index: 1;
            //}
            //&:after{
            //    content: '';
            //    position: absolute;
            //    bottom: 0;
            //    right:  0;
            //    width: 0;
            //    height: 4px;
            //    background-color: $color-orange;
            //    transition: $transition;
            //    z-index: 2;
            //}
            &:hover,
            &.active {
              transition: $transition;

              &:after {
                width: 100%;
                transition: $transition;
              }
            }
          }
        }
      }
    }
  }
}
