.question-pagination {
  width: 244px;
  flex-direction: column;
  display: flex;
  align-items: center;
  padding: 20px;
  border: 1px solid var(--color-border-inner);
  &.no-border-right {
    border-right: none;
  }
  &.no-border-left {
    border-left: none;
  }
  .grade {
    margin-bottom: 20px;
  }
  .btn-primary {
    margin: 1px 0 35px;
    width: 145px;
    height: 35px;
  }

  ul {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    li {
      width: 25%;
      height: 40px;
      padding: 3px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;

      a {
        display: flex;
        width: 100%;
        height: 100%;
        border: 1px solid var(--color-border-inner);
        background-color: var(--background-secondary);
        justify-content: center;

        &.disabled {
          border: 1px solid var(--color-border-inner);
          background-color: var(--color-border-inner);
          color: #787878;
        }

        &:hover,
        &.active {
          border: 1px solid var(--color-border-inner);
          background-color: $new-primary-blue;
          color: var(--contrast-light);
        }
      }
    }
  }
}

@media (max-width: 768px) {
}

@media (max-width: 578px) {
  .question-pagination {
    width: 100%;
  }
}
