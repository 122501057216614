@import "@airy-ui/cdk/styles";

@mixin air-grid($col-width, $indent) {
  --gap: #{$indent};
  .air-grid {
    gap: 0 #{$indent};
  }
  .air-grid-item {
    width: 100%;
  }

  $container-width: ($col-width * 12) + ($indent * (12 - 1));

  $indent-percent: (strip-unit($indent) / strip-unit($container-width)) * 100%;
  $col-width-percent: (strip-unit($col-width) / strip-unit($container-width)) * 100%;

  @for $i from 2 through 11 {
    $i2: $i - 1;
    --grid-item-#{$i}: #{($col-width-percent * $i) + ($indent-percent * $i2)};
    --fixed-grid-item-#{$i}: #{($col-width * $i) + ($indent * ($i - 1))};
    --filled-grid-item-#{$i}: #{($col-width-percent * $i) + ($indent-percent * $i)};
  }

  --grid-item-1: 8.3%;
  --filled-grid-item-1: 8.3%;
  --fixed-grid-item-1: $col-width;
  --filled-grid-item-12: 100%;
  --grid-item-12: 100%;

  --fixed-grid-item: #{$container-width};

  --container-max-width: #{$container-width};

  .air-cc, .air-cg {
    .air-grid-item {
      max-width: var(--col-width);
      width: 100%;
    }
  }
}
