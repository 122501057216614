@import "../../scss/var";

* {
  margin: 0;
  padding: 0;
  outline: none;
}

a {
  outline: none;
  text-decoration: none;
}

p {
  margin: 0;
  //color: var(--text-primary);


  //strong {
  //  span {
  //    color: var(--text-primary);
  //  }
  //}
  //
  //span {
  //  color: var(--text-primary);
  //}
}

strong {
  font-weight: bold;
}

a:hover {
  text-decoration: none;
}

ul,
li {
  margin: 0;
}

img {
  max-width: 100%;
}

html,
body {
  width: 100%;
  font-weight: 400;
  position: relative;
  min-height: 100vh;
  min-height: 100%;
  display: flex;

  &.admin:not(.dark-theme) {
    background: #f6f9ff;

    --input__background: var(--background__primary);
  }

  &.admin {
    tbody {
      background: var(--background-primary) !important;
    }
  }
}

body {
  --top: 0;

  &.modal-open {
    overflow: initial !important;
  }
}

.no-scroll {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-overflow-scrolling: touch;
}

.dark {
  background-color: rgba(0, 0, 0, 0.65);
}

main {
  height: max-content;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.white-bg {
  position: relative;
  background-color: var(--background-primary);
}

.white-bg-empty {
  background-color: var(--background-primary);
}

.blue-bg {
  position: relative;
  background-color: #015795;
}

.orange-dot-ul {
  ul {
    li {
      max-width: 90%;
      list-style-type: square;
      color: $new-primary-blue-text;

      span {
        color: #191919;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.error404 {
  svg {
    width: 50%;
    min-width: 250px;
    max-width: 580px;
    margin-bottom: 50px;
  }

  p {
    margin: 5px;
  }

  .error {
    font-weight: 700;
    text-transform: uppercase;
  }
}

.main-validation-message {
  svg {
    margin-bottom: 10px;
  }

  p {
    line-height: 1.5;
  }

  .error {
    color: red;
  }

  .success {
    color: green;
  }
}

.messahe-animation {
  height: 0;
  overflow: hidden;
  display: flex;
  animation: appearValidationMessages 3s 0.1s linear forwards;
}

@keyframes appearValidationMessages {
  to {
    height: 100%;
  }
}

input[type="tel"] {
  direction: ltr !important;

  &::placeholder {
    direction: rtl !important;
  }
}

.rotated180 {
  transform: rotate(180deg);
}

.flipX {
  transform: scaleX(-1);
}

.nowrap {
  white-space: nowrap;
}

yeda-header + i-content,
yeda-header + main {
  min-height: calc(100vh - 430px);
}

yeda-bundle-about {
  //min-height: calc(100% - 404px);
  display: block;
}

.privacy-download-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .terms-link {
    font-size: 15px;
    cursor: pointer;
    color: var(--color-primary);
  }

  &__info {
    display: flex;
    align-items: center;
  }
}

.social {
  &-btn {
    display: flex;
    gap: 10px;
    width: 100%;
    font-weight: 400;
    font-size: 16px;

    .mat-icon {
      width: 20px;
      height: 20px;
    }
  }

  &-or {
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-transform: uppercase;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 24px 0;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background: var(--color-border-inner);
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &__text {
      background: var(--background-primary);
      padding: 0 15px;
      position: relative;
    }
  }
}

yeda-login {
  .social-or__text {
    background: var(--background-secondary);
  }
}
