.contact {
  input,
  textarea {

    ::-webkit-input-placeholder {
      color: var(--text-secondary);
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--text-secondary);
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      color: var(--text-secondary);
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: var(--text-secondary);
    }
  }

  > div,
  air-container {
    padding: 20px;
    position: relative;

    h1 {
      font-weight: 400;
      margin-bottom: 20px;
    }

    .desc {
      margin-bottom: 20px;

      p {
        font-weight: 400;
      }
    }

    .contact-info {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      width: 100%;
      margin-bottom: 10px;
      font-weight: 400;

      > span {
        font-weight: 500;
        padding-inline-end: var(--indent-xxs);
      }
    }
  }

  .map {
    width: 100%;
    height: 300px;
  }
}

@media (max-width: 992px) {
}
