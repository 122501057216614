:root {
  --icon-display: inline-flex;
  --sm-icon-height: 16px;
  --sm-icon-width: 16px;

  --lg-icon-width: 60px;
  --lg-icon-height: 60px;

  --icon-background-size: 100%;
}
