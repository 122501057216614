@import "@airy-ui/cdk/styles";
@import "./src/lib/directives/input.directive";

.air-options {
  @include set-current-element(options);
  @include element-prop(display, block);
  @include element-prop(background, var(--background-primary));
  @include element-prop(width, 100%);
  @include element-prop(outline, none);
  @include element-prop(max-height, 300px);
  @include element-prop(overflow-y, auto);

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
}
.air-select-panel {
  @include element-prop(border, 2px solid var(--color-primary-light));
  .air-options {
    display: flex;
  }
  .active-select-border {
    position: absolute;
    border: 2px solid var(--color-primary-light);
    height: 100%;
    left: -2px;
  }
  air-option {
    flex-shrink: 0;
  }
  &.panel-bottom {
    @include element-prop(border-top, none);
    .air-options {
      flex-direction: column;
    }
    .active-select-border {
      top: -46px;
      border-bottom: none;
    }
    air-option {
      @include element-prop(border-top, 1px solid var(--border-color));
    }
  }
  &.panel-top {
    @include element-prop(border-bottom, none);
    .air-options {
      flex-direction: column-reverse;
    }
    .active-select-border {
      bottom: -46px;
      border-top: none;
    }
    air-option {
      @include element-prop(border-bottom, 1px solid var(--border-color));
    }
  }

}
