.load-more {
  display: flex;
  align-items: center;
  width: 100%;
  color: var(--color-primary-light-contrast);
  border-radius: 4px;
  background-color: var(--color-primary-light);
  height: 44px;
  transition: 200ms ease-in-out;
  justify-content: center;

  &:hover {
    background-color: var(--color-primary-dark);
    color: var(--text-button-color);
  }
}
