* {
  --mdc-outlined-button-container-shape: 4px;
  --mdc-filled-button-container-shape: 4px;
  --mdc-button-container-shape: 4px;
  --mdc-text-button-container-shape: 4px;
  --mdc-protected-button-container-shape: 4px;
  --mdc-dialog-container-shape: 4px;
  --mat-expansion-container-shape: 4px;
  --mat-list-active-indicator-shape: 4px;
  --mdc-protected-button-label-text-color: var(--primary-100);
  --mdc-protected-button-container-color: var(--color-primary);
  --mat-text-button-ripple-color: #A1A5C066;
  --mdc-filled-button-container-color: var(--color-primary);
  --mat-text-button-state-layer-color: var(--color-primary);
  --sys-primary: var(--color-accent) !important;
  --mdc-protected-button-label-text-font: Rubik, sans-serif;
  --mat-menu-item-label-text-font: Rubik, sans-serif;
  --mat-menu-item-label-text-weight: 400;
  --mat-standard-button-toggle-divider-color: #A1A5C066;
  --mat-divider-color: #A1A5C066;
  --mdc-outlined-text-field-outline-color: #A1A5C066;
  --mdc-outlined-text-field-label-text-color: #A1A5C0;
  --mdc-switch-handle-surface-color: #000;
  --mat-fab-ripple-color: #A1A5C066;
  --mat-outlined-button-ripple-color: #A1A5C066;
  //--mat-outlined-button-ripple-color: #FFFFFF19;
  --mat-ripple-color: transparent;
  --mat-form-field-error-text-color: var(--p-error-50);
  --mdc-outlined-text-field-error-label-text-color: var(--p-error-50);
  --mat-stepper-line-color: #a1a5c066;
  --mdc-text-button-label-text-color: var(--color-primary);
  --mdc-switch-unselected-focus-handle-color: #74777f;
  --mdc-switch-unselected-handle-color: #74777f;
  --mdc-switch-unselected-icon-color: #e0e2ec;
  // -------- Variables --------//
  --mdc-theme-error: #dc1833;
  --mdc-dialog-container-color: var(--background-secondary);
  --mdc-dialog-subhead-color: var(--text-primary);
  --mdc-dialog-supporting-text-color: var(--text-primary);
  --color-disabled-dark-contrast: var(--text-secondary);

  .mat-mdc-unelevated-button[disabled][disabled] {
    --mdc-filled-button-disabled-container-color: var(--text-secondary);
    --mdc-filled-button-disabled-label-text-color: #fff;
    --mdc-filled-button-container-color: var(--text-secondary);
    --mdc-filled-button-label-text-color: #fff;
  }

  .mat-mdc-unelevated-button:not(:disabled) {
  }

  // -------- Variables end--------//

  .mdc-text-field--filled:not(.mdc-text-field--disabled),
  .mat-mdc-form-field-focus-overlay {
    background-color: var(--input__background);
  }

  .mat-mdc-form-field-infix {
    min-height: 46px;
  }

  .phone-number {
    .mat-mdc-form-field-infix {
      min-height: 50px;
    }
  }

  .mdc-line-ripple::before {
    display: none;
  }

  //.mat-mdc-unelevated-button:not(:disabled),
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
  .mat-mdc-tab-link .mdc-tab__text-label,
  {
    color: var(--text-primary) !important;
  }

  .mat-mdc-form-field:hover {
    opacity: 1;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 26px;
  }
}

.materials-form {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__group {
    width: 100%;
    margin-bottom: 24px;

    &--outer {
      position: relative;
      width: 100%;

      i-password-strength {
        position: absolute;
        bottom: -10px;
        left: -6px;
        width: calc(100% + 6px);
      }
    }

    &__icon,
    air-dynamic-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 0;
    }

    .mdc-floating-label--float-above {
      mat-label,
      .mat-mdc-form-field-required-marker:after {
        color: var(--color-primary);
        font-size: 16px;
      }
    }

    .error-tailor-has-error {
      .mdc-floating-label--float-above {
        mat-label,
        .mat-mdc-form-field-required-marker:after {
          color: var(--color-danger);
        }
      }
    }

    .phone-number {
      border: 0;
      border-radius: 4px;

      &__code {
        border-radius: 4px 0 0 4px;
      }
    }
  }

  &__bottom-text {
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-top: 24px;

    .terms-link {
      font-size: 12px;
      color: var(--color-primary);
      cursor: pointer;
    }
  }

  &__link {
    color: var(--color-primary);
    margin-top: 24px;
    display: block;
    font-size: 12px;
  }

  mat-error,
  control-error {
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-danger);
    height: 17px;

    control-error {
      bottom: 0;
    }
  }

  control-error {
    font-size: 12px;

    & > label {
      font-size: 12px;
    }
  }

  mat-label,
  .mat-mdc-form-field-required-marker:after {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--text-secondary);
  }

  .mdc-button {
    height: 48px;
    display: inline-flex;
    width: max-content;
    min-width: 100px;
    border-radius: 6px;

    &__label {
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.5px;
    }
  }
}

.cdk-global-overlay-wrapper {
  @media (max-width: 992px) {
    padding: 0 10px;
  }
}

.mdc-button.modal__close-btn {
  position: absolute;
  top: 20px;
  right: 5px;

  .mat-icon {
    height: 20px;
  }
}

.mat-mdc-dialog-container {
  h2.mdc-dialog__title {
    font-size: 32px;
    line-height: 42px;
    font-weight: 500;
    padding-top: 15px;
  }
}

.mat-mdc-form-field-bottom-align:before {
  position: absolute;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: #a1a5c066;
}

.mat-elevation-z8,
.mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px #a1a5c023,
  0px 8px 10px 1px #a1a5c023,
  0px 3px 14px 2px #a1a5c023;
}

.cdk-overlay-connected-position-bounding-box {
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: #A1A5C0 !important;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  //background-color: #FFFFFF !important;
}

.mdc-menu-surface {
  background-color: var(--background-secondary);
}

.mat-mdc-menu-content {
  background-color: var(--background-secondary);
}

.mdc-list-item__primary-text {
  color: var(--text-primary);
}

.mat-mdc-menu-item-text {
  color: var(--text-primary);
}

body.ltr {
  .menu-content, .mat-mdc-menu-content, .mat-mdc-option, .mdc-list-item__primary-text {
    direction: ltr;
  }
}

body.rtl {
  .menu-content, .mat-mdc-menu-content, .mat-mdc-option, .mdc-list-item__primary-text {
    direction: rtl;
  }
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: var(--color-primary)
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
  border-color: var(--color-primary);
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: var(--text-primary);
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: var(--background-primary) !important;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:first-child)::before,
.mat-stepper-label-position-bottom .mat-horizontal-stepper-header:not(:last-child)::after,
.mat-stepper-horizontal-line {
  border-top-color: #a1a5c066 !important;
}

.mat-mdc-outlined-button:not(:disabled) {
  border-color: var(--color-primary) !important;
}

* .mat-mdc-unelevated-button:not(:disabled) {
}

.mat-step-text-label {
  color: var(--text-primary);
}

.mat-mdc-input-element {
  &::placeholder {
    color: var(--text-secondary) !important;
  }
}

.mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
  color: #DC1833;
}

.mat-step-icon-content {
  color: #FFFFFF;
}

.mat-mdc-fab .mdc-button__label,
.mat-mdc-fab .mat-icon,
.mat-mdc-mini-fab .mdc-button__label,
.mat-mdc-mini-fab .mat-icon,
.mat-mdc-outlined-button .mat-icon,
.mat-mdc-button .mat-icon,
{
  z-index: 1;
  position: relative;
}