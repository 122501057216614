:root {
  --color-primary: #0a59eb;
  --color-primary-contrast: #fff;
  --color-primary-shade: #0765b4;
  --color-primary-tint: #0286f1;
  --color-primary-hover-background: linear-gradient(to bottom, #0286f1, #0765b4);
  --color-primary-hover: #0765b4;

  --color-background-secondary: #f6f9ff;

  --color-default: transparent;
  --color-default-shade: #f5fafd;
  --color-default-tint: #f5fafd;

  --input-border-color: rgba(69, 158, 233, 0.39);
  --input-border-color-2: rgba(161, 165, 192, 0.4);

  --color-border: #f4f5f8;
  --color-border-inner: #f4f5f8;

  --color-info: #039fbc;

  --color-success: #29ce6c;

  --color-danger: #f1526e;

  --color-warning: #ffb200;

  --color-muted: #a1a5c0;

  --color-light: #ebeeff;

  --border-color-table: #dfe5eb;

  --item-box-shadow: #c4cbdd;
  --item-box-shadow-hover: #dee8ff;

  --input__background: #f2f2f9;

  --color-yeda-logo-lms: #000f61;
  --color-yeda-logo-org: #0a59eb;
}
