@import "../../scss/var";

.college-courses {
  margin-top: 5px;

  > .container {
    background-color: var(--background-primary);
    padding: 20px 20px 0;
    position: relative;

    .courses-content {
      justify-content: center;
      display: flex;

      .courses-item {
        cursor: pointer;
        position: relative;
        width: 100%;
        max-width: 650px;
        box-shadow: $box-shadow;
        transition: $transition;
        margin-bottom: 20px;
        padding: 15px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: right;
        flex-wrap: wrap;

        &:hover {
          box-shadow: $new-shadow-block;
        }

        .logo {
          width: 125px;
          height: 125px;
          border-radius: 50%;
          margin-left: 15px;
          display: flex;
          flex-wrap: wrap;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .inform {
          //width: calc(100% - 140px);
          display: flex;
          flex-wrap: wrap;

          .short-desc {
            color: $color-dark;
            font-weight: 300;
            width: 100%;
            line-height: 20px;
            margin: 10px 0 10px;

            a {
              transition: $transition;

              &:hover {
                color: $color-blue;
              }
            }
          }

          a.title-name {
            color: $color-dark;
            line-height: 1;
            cursor: pointer;
            transition: $transition;

            &:hover {
              color: $color-blue;
            }
          }
        }
      }
    }
  }
}

.lesson_part_player_container iframe {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  //   opacity: 0;
  //   transition: all .2s ease-in;
  transition-delay: 1s;
  //   height: calc(39.25vw);
}

.lesson_part_player_container iframe.visibility {
  opacity: 1;
}

.video-block iframe {
  max-width: 100%;
  min-width: 100%;
  width: 100%;
  // opacity: 0;
  // transition: all .2s ease-in;
  //   height: calc(39.25vw) !important;
}

.video-block.visibility {
  opacity: 1;
}

.video-block iframe.visibility {
  opacity: 1;
}

.file-list {
  span {
    display: block;

    a {
      display: block;
    }
  }
}

.my-courses {
  h2 {
    font-weight: 500;
    letter-spacing: -0.3px;
  }

  .content-menu ul li a {
    font-weight: 400;
  }

  .my-courses-info {
    > .container {
      background-color: var(--background-primary);
      padding: 20px;
      position: relative;

      .user-info {
        border-bottom: 1px solid #e1e1e1;
        padding-bottom: 25px;

        li {
          //margin-bottom: 10px;
        }

        li:last-child {
          margin-bottom: 0;
        }

        svg {
          width: 25px;
          height: 25px;
        }

        span {
          line-height: 1;
          vertical-align: middle;
        }
      }

      .courses-content {
        justify-content: right;
        display: flex;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .courses-item {
          cursor: pointer;
          position: relative;
          //width: 100%;
          box-shadow: $box-shadow;
          transition: $transition;
          //padding: 15px;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          text-align: right;
          flex-wrap: wrap;

          &:hover {
            box-shadow: $new-shadow-block;
          }

          .logo {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            margin-left: 15px;
            display: flex;
            flex-wrap: wrap;
            position: relative;

            img {
              object-fit: cover;
              width: 100%;
              height: 100%;
            }

            .icon {
              position: absolute;
              top: -12px;
              left: 60%;
            }
          }

          .inform {
            //width: calc(100% - 165px);
            display: flex;
            flex-wrap: wrap;

            .short-desc {
              color: $color-dark;
              font-weight: 300;
              line-height: 20px;
              display: flex;
              flex-direction: column;
              margin-bottom: 5px;
              margin-top: 5px;

              .category {
                color: #787878;
                font-weight: 400;
              }

              .progres {
                margin-bottom: 14px;
                font-weight: 400;
                margin-top: 5px;
                color: $footer-text-font-color;
              }

              .start-data {
                font-weight: 400;
                margin-bottom: 5px;
                color: $course-category-price-color;
              }
            }

            .progress {
              width: 100%;
              background-color: var(--color-border-inner);
              //height: 8px;
              border-radius: 0;

              .progress-bar {
                background-color: #ff9703;
              }
            }

            a.title-name {
              cursor: pointer;
              width: 100%;
              color: $color-dark;
              font-weight: 400;
              line-height: 1;
              transition: $transition;
              margin-bottom: 5px;
              margin-top: 5px;

              &:hover {
                color: $color-blue;
              }
            }
          }

          .btn-primary {
            position: absolute;
            left: 15px;
            //top: 55%;
            bottom: 35px;
            transform: translateY(-50%);
            width: 180px;
          }
        }
      }
    }
  }
}

.course-information {
  .course-i-header {
    padding: 45px 0;
    z-index: 1;
    position: relative;

    h1 {
      font-weight: 500;
      letter-spacing: -0.24px;
      margin-bottom: 15px;
      line-height: 1;
      color: $color-dark;
    }

    .info-header {
      h2 {
        display: inline;
        font-weight: 300;
        letter-spacing: 0.09px;
        color: $color-dark;
        vertical-align: middle;
      }

      .logo-block {
        display: inline;

        img,
        svg {
          height: 100%;
          width: 100%;
          object-fit: contain;
          max-height: 40px;
          max-width: 80px;
        }
      }
    }

    .place-for-graphics {
      width: 100%;

      img,
      svg {
        object-fit: cover;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .description {
      font-weight: 300;
      letter-spacing: 0.07px;
      line-height: 20px;
      padding: 40px 0;

      p {
        margin-bottom: 15px;
        line-height: 1;

        .desc-span {
          margin-left: 5px;
          font-weight: 500;
        }

        a {
          &:hover {
            color: $color-blue-hover;
            transition: $transition;
          }
        }

        span:last-child {
          max-width: 75%;
        }
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .btn-primary {
      width: 180px;
      letter-spacing: 1.2px;
    }
  }

  .courses-i-shedule {
    margin-top: 30px;

    .title {
      color: #191919;
      font-weight: 600;
      line-height: 1;
      margin-bottom: 10px;
      letter-spacing: -0.18px;
    }

    .shedule-row {
      padding: 18px 0;
      border-bottom: 1px solid #e1e1e1;

      &:last-child {
        //border-bottom: none;

        .shedule-details {
          border-bottom: none;
        }
      }

      .date-day span {
        margin-left: 0.4em;
      }

      .shedule-briefly {
        margin: 0;

        div {
          display: flex;
          align-items: center;
          justify-content: center;

          span {
            color: $footer-text-font-color;
          }

          .lecturer-name {
            width: 90%;
            margin-right: 20%;
            text-align: right;
          }

          .time {
            margin-right: 10%;
            text-align: right;
          }
        }

        button {
          width: 120px;
          height: 30px;
          text-transform: none;
          color: #000000;
        }
      }

      button:before {
        content: "לוח זמנים פתוח";
      }

      button[aria-expanded="true"]:before {
        content: "לוח זמנים סגור";
      }

      button[aria-expanded="false"]:before {
        content: "לוח זמנים פתוח";
      }

      .shedule-details {
        margin: 0;
        position: relative;

        span {
          color: $footer-text-font-color;
        }

        p {
          font-weight: 300;
          margin-bottom: 5px;
          line-height: 1.5;
        }

        p:first-child {
          line-height: 1;
          font-weight: 400;
          margin-bottom: 15px;
        }

        p:last-child {
          margin-bottom: 0;
        }

        .table-date {
          width: 100%;

          p {
            font-weight: 300;
            line-height: 1.5;
          }

          > div:first-child p {
          }

          div:last-child {
            margin-bottom: 0 !important;
          }

          .town {
            //font-weight: 700;
          }
        }
      }

      // .collapsing {
      //     display: table;

      //     transition: all 1s;
      // }

      // .show.row.row{
      //     display: table;
      //     transition: all 1s;
      // }

      .town {
        padding-left: 1.5rem;

        p {
          font-weight: 500;
          word-break: break-word;
          color: $color-dark;
        }
      }
    }

    .syllabus {
      line-height: 1;
      font-weight: 300;

      .syllabus-row {
        padding: 27px 0 20px 0;
        border-bottom: $light-gray-border;

        &:last-child {
          //border-bottom: none;
        }

        .title-part {
          display: flex;

          span:first-child {
            width: 20px;
            height: 20px;
            border: 1px solid #e1e1e1;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-inline-end: 15px;
          }
        }
      }
    }
  }

  .course-i-about {
    margin-top: 60px;
    padding: 20px 0 60px;

    h2 {
      font-weight: 600;
      letter-spacing: -0.18px;
      margin-bottom: 24px;
      line-height: 1;
      color: #191919;
    }

    .description {
      font-weight: 300;
      letter-spacing: 0.07px;
      padding-bottom: 20px;

      .sub-title {
        margin-top: 1px;
        font-weight: 600;
        line-height: 2;
      }

      p {
        line-height: 2;
      }

      p:last-child {
        margin-bottom: 0;
      }
    }

    .btn-primary {
      width: 180px;
      letter-spacing: 1.2px;
    }
  }

  .course-i-teacher {
    border-top: solid 1px rgba(161, 165, 192, 0.15);
    padding-top: 50px;

    .slick-dots {
      margin-top: 30px;
      position: static;
    }

    .slick-dots li button:before,
    .slick-dots li.slick-active button:before {
      opacity: 1;
      content: "";
      width: 12px;
      height: 12px;
      border: solid 1px #0780e5;
      border-radius: 50%;
    }

    .slick-dots li.slick-active button:before {
      background: #0780e5;
    }

    .header {
      color: $color-dark;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 20px;
    }

    .teacher-block {
      border-radius: 4px;
      border: solid 1px #f1f2f6;
      padding: 28px 28px 42px 28px;
      //margin-left: 16px;
      //margin-right: 16px;
      width: 99%;
      min-height: 240px;

      .photo {
        display: flex;

        .image {
          display: inline-block;
          vertical-align: top;
          flex-shrink: 0;

          img {
            width: 60px;
            height: 60px !important;
            object-fit: cover;
            border-radius: 50%;
          }
        }

        .info {
          display: inline-block;
          vertical-align: top;
          margin-inline-start: 16px;

          div:first-child {
            font-weight: 500;
            line-height: 1;
            //text-align: right;
            display: flex;
            color: #0780e5;
            padding-bottom: 8px;
          }

          div:nth-child(2) {
            line-height: 1;
            text-align: right;
            color: #a1a5c0;
          }
        }
      }

      .inform {
        line-height: 1.57;
        color: #292929;
        margin-top: 14px;
      }
    }
  }
}

.slick-dots li {
  width: 12px;
  margin: 0 9px;

  button {
    width: 12px;
  }
}

@media (max-width: 783px) {
  .course-information .course-i-teacher .teacher-block {
    max-width: 98%;
    margin-left: auto;
    margin-right: auto;
    padding: 24px 24px 72px 24px;
  }
}

.lesson {
  .container {
    position: relative;
    padding: 0 20px;

    > .row {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  .navigation {
    padding: 20px 0;
  }

  .lesson-body {
    padding: 20px 0;
    // test
    width: 100%;

    .title {
      line-height: 1;
      padding: 0 20px;
      font-weight: 400;
      color: var(--text-primary);
    }

    .description {
      color: var(--text-primary);
      font-weight: 300;
      padding: 20px;
      margin: 15px 0;
    }

    .about-lesson {
      padding: 0 20px;
      max-width: 100%;
      width: 100%;

      @media screen and (max-width: 575px) {
        padding: 0 10px;
      }


      div {
        font-weight: 400;
        line-height: 1;
        margin-bottom: 10px;

        &.additional-info {
          font-weight: 700;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      .files a {
        display: block;
        word-break: break-word;
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
      }
    }
  }
}

.slider-lesson {
  width: 870px;
  margin: auto;

  .slider-for {
    padding: 20px 0 15px;
  }

  .slider-nav {
    width: 95%;
    margin: auto;

    .slick-track {
      min-width: 100%;
    }

    .slick-slide {
      margin: 0 1px;

      .item {
        border: 1px solid $color-gray;

        .short-desc {
          display: flex;
          height: 100px;

          img {
            width: 65%;
          }

          span {
            padding: 1px;

            p {
              font-weight: 400;

              &:first-child {
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    .slick-prev {
      right: -30px;
      transform: rotate(270deg) translate(50%);

      &:before {
        content: url("data:image/svg+xml; utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 485.21 485.21'><path fill='#ff9703' d='M242.61,363.91,0,242.6V121.3L242.61,242.6l242.6-121.3V242.6Z'/></svg>");
        filter: grayscale(100%);
        transition: $transition;
      }

      &:hover:before {
        filter: grayscale(0);
      }
    }

    .slick-next {
      left: -27px;
      transform: rotate(90deg) translate(-50%);

      &:before {
        content: url("data:image/svg+xml; utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 485.21 485.21'><path fill='#ff9703' d='M242.61,363.91,0,242.6V121.3L242.61,242.6l242.6-121.3V242.6Z'/></svg>");
        filter: grayscale(100%);
        transition: $transition;
      }

      &:hover:before {
        filter: grayscale(0);
      }
    }
  }
}

.course-practice {
  .navigation {
    padding: 20px 0;
  }

  .practice-part {
    padding: 20px;

    //@media (max-width: 580px) {
    //  padding-inline-start: 40px;
    //}

    .title {
      color: var(--text-primary);
      margin-bottom: 20px;
    }

    .brief {
      font-weight: 400;
      margin-bottom: 25px;
      line-height: 1;

      p {
        &:first-child {
          margin-bottom: 1px;
        }

        span:first-of-type {
          font-weight: 700;
        }
      }
    }

    .question {
      margin-bottom: 25px;
      line-height: 1;

      display: flex;

      &-number {
        padding-inline-end: 0.5rem;
      }
    }

    .course-practice-body {
      width: 100%;

      .solution-info {
        padding: 0 15px;
        margin-top: 25px;
        width: 100%;

        h3 {
          font-weight: 400;
          margin-bottom: 18px;
          width: 100%;
        }

        p {
          max-width: 750px;
        }

        iframe {
          width: 100% !important;
        }

        .video {
          position: relative;

          &:before {
            content: "על ידי השתלת הומור או מילים אקראיות שלא ";
            position: absolute;
            top: 2%;
            right: 1%;
            padding: 5px 10px;
            background: rgba(0, 0, 0, 0.35);
            color: $color-white;
            line-height: 1;
            border-radius: 2px;
          }
        }
      }

      .description {
        color: var(--text-primary);
        font-weight: 300;
        padding: 20px;
        margin: 20px -20px;

        @media (max-width: 578px) {
          margin: 0;
        }

        p {
          margin-bottom: 1px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .btn-next-prev {
        max-width: 245px;
        width: fit-content;
        height: 48px;
        font-weight: 500;
        text-transform: inherit;
        letter-spacing: 0.72px;

        &:last-child {
          margin-inline-start: 20px;

          &:after {
            content: url("/assets/img/arrow-left.svg");
            margin-inline-start: 10px;
            line-height: 0;
          }
        }

        &:first-child {
          &:before {
            content: url("/assets/img/arrow-right.svg");
            margin-inline-end: 10px;
            line-height: 0;
          }
        }
      }

      .btn-start {
        max-width: 245px;
        width: fit-content;
        height: 48px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1.2px;

        @media (max-width: 578px) {
          width: 100%;
          margin-top: 40px;
        }
      }

      .display-solution {
        p {
          cursor: pointer;
          color: var(--text-secondary);
          text-transform: uppercase;
          font-weight: 500;
        }
      }
    }

    ul,
    ol {
      padding-right: 20px;
      padding-bottom: 15px;
    }

    p {
      padding-bottom: 15px;
    }

    table {
      tr,
      td {
        border: 1px solid var(--border-color-table);
      }
    }

    a {
      text-decoration: underline;
      color: var(--color-primary);
    }
  }
}

.survey {
  ul,
  ol {
    padding-right: 20px;
    padding-bottom: 15px;
  }

  p {
    padding-bottom: 15px;
  }

  table {
    tr,
    td {
      border: 1px solid var(--border-color-table);
    }
  }

  a {
    text-decoration: underline;
    color: var(--color-primary);
  }
}

.left-content {
  display: flex;
  width: calc(100% - 24px);
}

.row-sidebar {
  flex-wrap: nowrap;
}

@media (max-width: 1200px) {
  .slider-lesson {
    width: 690px;

    .slider-nav {
      width: 95%;

      .slick-slide {
        margin: 0 1px;

        .item {
          .short-desc {
            height: 80px;

            img {
              width: 60%;
            }

            span {
              padding: 7.5px;
            }
          }
        }
      }

      .slick-prev {
        right: -27px;
      }

      .slick-next {
        left: -26px;
      }
    }
  }
}

@media (max-width: 992px) {
  // app-not-accessible-item-message{
  //     min-height: 200px;
  // }
  .left-content {
    width: calc(100%);
  }
  .course-information {
    .courses-i-shedule {
      .shedule-row {
        .lecturer-name {
          width: 95%;
          margin-right: 5%;
        }

        .time {
          width: 95%;
          margin-right: 5%;
        }
      }
    }
  }

  .lesson,
  .course-practice {
    .navigation-side {
      top: 0;
      bottom: 0;
    }
  }

  .slider-lesson {
    width: 460px;

    .slider-nav {
      width: 95%;

      .slick-slide {
        margin: 0 1px;

        .item {
          .short-desc {
            height: 80px;

            img {
              width: 60%;
            }

            span {
              padding: 7.5px;
            }
          }
        }
      }

      .slick-prev {
        right: -20px;
      }

      .slick-next {
        left: -20px;
      }
    }
  }
}

@media (max-width: 768px) {
  .course-information {
    .course-i-header {
      .description {
        padding: 20px 0;

        p {
          margin-bottom: 1px;

          span:last-child {
            max-width: 100%;
          }
        }
      }
    }

    .courses-i-shedule {
      .shedule-row {
        .shedule-briefly {
          div {
            span {
              text-align: center;
            }
          }

          button {
            width: 60px;
            min-width: 100%;
          }
        }

        .shedule-details {
          margin: 0;
          // padding: 15px 0;
          //border-top: 1px solid $color-orange;
          border-bottom: $light-gray-border;

          p {
            font-weight: 300;
            margin-bottom: 0;
            line-height: 1;
          }

          p:first-child {
            line-height: 1;
            font-weight: 400;
            margin-bottom: 15px;
          }

          p:last-child {
            margin-bottom: 0;
          }
        }
      }

      .syllabus {
        .syllabus-row {
          ul {
            li {
              margin-bottom: 1px;
              max-width: 100%;
            }
          }
        }
      }
    }

    .course-i-teacher {
      .teacher-block {
        .inform {
          .short-desc {
            p {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .my-courses {
    .my-courses-info {
      > .container {
        .courses-content {
          .courses-item {
            .btn-primary {
              width: 150px;
            }
          }
        }
      }
    }
  }
  .course-information .course-i-teacher .teacher-block .inform .short-desc {
    text-align: center;
  }
}

@media (max-width: 578px) {
  .course-information .course-i-teacher .teacher-block {
    min-height: auto;
    padding-bottom: 40px !important;
  }
  .my-courses .my-courses-info > .container .courses-content .courses-item:hover {
    box-shadow: $new-shadow-block;
  }

  .course-information {
    .course-i-header {
      .description {
        padding: 20px 0;

        p {
          margin-bottom: 1px;

          span:last-child {
            max-width: 100%;
          }
        }
      }
    }

    .courses-i-shedule {
      .shedule-row {
        .shedule-briefly {
          div {
            .lecturer-name {
              margin-right: 0;
            }
          }

          button {
            width: 70%;
            min-width: 55px;
          }
        }

        .shedule-details {
          p {
            text-align: center;
          }
        }
      }

      .syllabus {
        .syllabus-row {
          ul {
            li {
              margin-bottom: 1px;
              max-width: 100%;
            }
          }
        }
      }
    }

    .course-i-teacher {
      .teacher-block {
        padding: 16px 16px 72px 16px;

        .inform {
          .short-desc {
            p {
              text-align: center;
            }
          }
        }
      }
    }
  }

  .course-practice {
    .practice-part {
      .course-practice-body {
        .btn-primary {
          width: 110px;
          min-width: 90px;
          height: 30px;

          &:last-child {
            margin-right: 1px;

            &:after {
              margin-right: 5px;
            }
          }

          &:first-child {
            &:before {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  .my-courses {
    .my-courses-info {
      > .container {
        .courses-content {
          .courses-item {
            .logo {
              width: 150px;
              height: 150px;
              margin-left: 0;
            }

            .inform {
              width: 100%;

              .short-desc {
                span {
                  text-align: center;
                }

                .start-data {
                  margin-bottom: 5px;
                }
              }

              a.title-name {
                //text-align: center;
              }
            }

            .btn-primary {
              position: relative;
              top: 0;
              left: 0;
              transform: none;
              width: 180px;
            }
          }
        }
      }
    }
  }

  .slider-lesson {
    width: 320px;

    .slider-nav {
      width: 95%;

      .slick-slide {
        margin: 0 1px;

        .item {
          .short-desc {
            height: 50px;

            img {
              width: 60%;
            }

            span {
              padding: 5px;
            }
          }
        }
      }

      .slick-prev {
        right: -19px;
      }

      .slick-next {
        left: -19px;
      }
    }
  }
  .course-i-teacher {
    .photo {
      margin: auto;
    }
  }
  .course-information .course-i-teacher .teacher-block .inform .short-desc {
    text-align: center;
  }
  .left-content {
    flex-wrap: wrap;
  }
}

@media (max-width: 500px) {
  .college-courses {
    margin-top: 20px;
  }
}

@media (max-width: 450px) {
  .college-courses {
    > .container {
      .courses-content {
        .courses-item {
          justify-content: center;

          .logo {
            margin-bottom: 15px;
            margin-left: 0;
          }

          .inform {
            width: 100%;
            justify-content: center;

            .short-desc {
              p {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .slider-lesson {
    width: 260px;

    .slider-nav {
      .slick-slide {
        .item {
          .short-desc {
            height: 80px;
          }
        }
      }
    }
  }
}

.name-sheldule-detail {
  color: $color-dark;
}

.rotated-before-after {
  &:before,
  &:after {
    transform: rotate(180deg);
  }
}

.progres {
  margin-bottom: 14px;
  font-weight: 400;
  margin-top: 5px;
  color: var(--text-secondary);
}