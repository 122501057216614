table:not(.mat-calendar-table) {
  width: 100%;
  border: 1px solid var(--color-border);
  background: var(--background-secondary);

  th {
    color: var(--text-secondary);
    font-weight: 400;
  }

  thead {
    background-color: var(--color-light);

    tr {
      height: 50px;
    }
  }

  th,
  td {
    padding: 5px 10px;
    color: var(--text-secondary);

    &:first-child {
      padding-inline-start: 30px;
    }

    &:last-child {
      padding-inline-end: 30px;
    }
  }

  tbody {
    background: var(--background-secondary);

    tr {
      height: 72px;

      &:not(:last-child) {
        border-bottom: 1px solid var(--color-border-inner);
      }
    }
  }

  td {
    font-size: var(--text2__font-size, 16px);
    color: var(--text-primary);
  }
}
