:root {
  // size
  --indent-xxs: 8px;
  --indent-xs: 12px;
  --indent-s: 16px;
  --indent-m: 20px;
  --indent-xm: 24px;
  --indent-l: 28px;
  --indent-xl: 40px;
  --indent-xxl: 60px;
  //---
}