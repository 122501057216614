.information,
.article {
  padding-top: 6rem;

  .container {
    position: relative;
    background-color: var(--background-primary);
    padding: 2rem;

    .description {
      .place-for-graphics {
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        float: left;
        margin-right: 1.5rem;
        margin-bottom: 5rem;
        justify-content: center;
        align-items: center;
        div {
          width: 100%;
          height: 100%;
          img,
          svg {
            object-fit: cover;
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
          }
          iframe {
            max-width: 100%;
            max-height: 100%;
          }
        }
      }

      .info-header {
        h1 {
          display: inline;
          font-weight: 300;
          color: var(--text-primary);
          vertical-align: middle;
        }

        .logo-block {
          display: inline;

          img,
          svg {
            height: 100%;
            //width: 100%;
            object-fit: contain;
            max-height: 4rem;
            max-width: 8rem;
          }
        }
      }

      .title-name,
      .article-title {
        color: var(--text-primary);
        font-weight: 400;
        margin-top: 3rem;
      }

      .article-title {
        margin-top: 0;
      }

      p {
        line-height: 2.2rem;
        font-weight: 400;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.article {
  height: 100%;
  .container {
    .description {
      .place-for-graphics {
        margin-right: 1.5rem;
        margin-bottom: 1.5rem;
        margin-top: 0;

        img,
        svg {
          object-fit: cover;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .information,
  .article {
    .container {
      padding-top: 0;

      .description {
        .article-title {
          margin-top: 1.5rem;
        }

        .place-for-graphics {
          max-width: 90rem;
          display: flex;
          flex-wrap: wrap;
          float: unset;
          width: 100%;
          margin-right: 0;
          margin-bottom: 0;
          margin-top: 2rem;
          div {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media (max-width: 578px) {
  .information,
  .article {
    .container {
      .description {
        .place-for-graphics {
          width: 100%;
          margin-right: 0;
          margin-bottom: 3rem;
          margin-top: 3rem;
          div {
            display: flex;
            justify-content: center;
            iframe {
              max-width: 100%;
              max-height: 100%;
            }
          }
        }
      }
    }
  }
  .timetable {
    min-height: 400px;
  }
}
@media (max-width: 990px) {
  .information,
  .article {
    .container {
      .row {
        margin-left: 0;
      }
      .place-for-graphics {
        .vimeo-div {
          margin-left: -7px;
        }
      }
    }
  }
}
@media (max-width: 480px) {
  .information,
  .article {
    .container {
      .description {
        .place-for-graphics {
          iframe {
            width: 100%;
          }
        }
      }
    }
  }
}
