@use "sass:map";
@use "@angular/material" as mat;
@import "themes";
@include mat.core();

@mixin theme-init() {
  @import "@angular/cdk/overlay-prebuilt.css";
  :root {
    --primary-0: #000000;
    --primary-10: #001f24;
    --primary-20: #00363d;
    --primary-25: #00424a;
    --primary-30: #004f58;
    --primary-35: #005b66;
    --primary-40: #006874;
    --primary-50: #068391;
    --primary-60: #389eac;
    --primary-70: #58b9c7;
    --primary-80: #75d4e4;
    --primary-90: #98f0ff;
    --primary-95: #d0f8ff;
    --primary-98: #edfcff;
    --primary-99: #f6feff;
    --primary-100: #ffffff;

    --p-secondary-0: #000000;
    --p-secondary-10: #001f24;
    --p-secondary-20: #00363d;
    --p-secondary-25: #00424a;
    --p-secondary-30: #004f58;
    --p-secondary-35: #005b66;
    --p-secondary-40: #006874;
    --p-secondary-50: #068391;
    --p-secondary-60: #389eac;
    --p-secondary-70: #58b9c7;
    --p-secondary-80: #75d4e4;
    --p-secondary-90: #98f0ff;
    --p-secondary-95: #d0f8ff;
    --p-secondary-98: #edfcff;
    --p-secondary-99: #f6feff;
    --p-secondary-100: #ffffff;

    --p-error-0: #000000;
    --p-error-10: #410002;
    --p-error-20: #690005;
    --p-error-25: #7e0007;
    --p-error-30: #93000a;
    --p-error-35: #a80710;
    --p-error-40: #ba1a1a;
    --p-error-50: #de3730;
    --p-error-60: #ff5449;
    --p-error-70: #ff897d;
    --p-error-80: #ffb4ab;
    --p-error-90: #ffdad6;
    --p-error-95: #ffedea;
    --p-error-98: #fff8f7;
    --p-error-99: #fffbff;
    --p-error-100: #ffffff;

    --tertiary-0: #000000;
    --tertiary-10: #410003;
    --tertiary-20: #690007;
    --tertiary-25: #7d000a;
    --tertiary-30: #93000e;
    --tertiary-35: #a60f16;
    --tertiary-40: #b81f21;
    --tertiary-50: #db3a36;
    --tertiary-60: #ff544c;
    --tertiary-70: #ff897f;
    --tertiary-80: #ffb4ac;
    --tertiary-90: #ffdad6;
    --tertiary-95: #ffedea;
    --tertiary-98: #fff8f7;
    --tertiary-99: #fffbff;
    --tertiary-100: #ffffff;

    --t-secondary-0: #000000;
    --t-secondary-10: #350f0d;
    --t-secondary-20: #4f2320;
    --t-secondary-25: #5c2e2a;
    --t-secondary-30: #6a3935;
    --t-secondary-35: #774440;
    --t-secondary-40: #85504b;
    --t-secondary-50: #a26862;
    --t-secondary-60: #bf817b;
    --t-secondary-70: #dc9b94;
    --t-secondary-80: #fab5ae;
    --t-secondary-90: #ffdad6;
    --t-secondary-95: #ffedea;
    --t-secondary-98: #fff8f7;
    --t-secondary-99: #fffbff;
    --t-secondary-100: #ffffff;
  }

  html,
  body {
    @include mat.system-level-colors($theme-light);
    @include mat.system-level-typography($theme-light);
    @include mat.core-theme($theme-light);
    @include mat.all-component-themes($theme-light);
    //@include mat.color-variants-backwards-compatibility($theme-light);
    .dark-theme {
      @include mat.system-level-colors($theme-dark);
      @include mat.all-component-colors($theme-dark);
    }

    &,
    .mat-app-background {
      background-color: var(--palette-background-background) !important;
    }
    .cdk-global-overlay-wrapper {
      padding: 0;
    }
    .mat-calendar-previous-button::after,
    .mat-calendar-next-button::after {
      margin: 10.5px !important;
    }
    &.yeda-customizer-active {
      yeda-visually-impaired,
      yeda-wl-admin-menu {
        display: none !important;
      }
    }
  }
}
