@import "../../scss/var";

.content-menu {
  .forum {
    position: relative;
    .badge {
      background-color: $forum-badge-color;
      color: #fff;
      font-weight: 400;
      padding: 0.3rem 0.4rem;
      margin-right: 5px;
    }
  }
}

.forum {
  .forum-content {
    position: relative;
    background-color: $color-white;

    form {
      width: 100%;
    }

    .form-row {
      padding: 2rem;
      padding-top: 40px;
      margin: auto;

      .btn-primary {
        height: 3.5rem;
        width: 17rem;
        font-weight: 500;
        letter-spacing: 0.72px;
      }

      .search {
        width: 40rem;
        height: 3.5rem;
        position: relative;

        input {
          width: 100%;
          padding: 1.3rem;
          height: 100%;
          border-radius: 0.2rem;
          border: 0.1rem solid #e1e1e1;
          background-color: $color-white;
        }

        ::placeholder {
          color: $course-category-price-color;
        }

        button {
          position: absolute;
          cursor: pointer;
          transition: all 0.5s;
          top: 0;
          left: 0;
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 0.2rem;
          border: 0.1rem solid #e1e1e1;
          background-color: $color-white;
          display: flex;

          svg {
            width: 50%;
            margin: auto;
            fill: $color-gray;
          }
        }
      }

      .date {
        position: relative;

        select {
          width: 100%;
          height: 3.5rem;
          border: 0.1rem solid #e1e1e1;
          background-color: $color-white;
          cursor: pointer;
          line-height: normal;
          -webkit-appearance: none;
          -moz-appearance: none;
          -ms-appearance: none;
          appearance: none;
          border-radius: 6px;
          display: block;
          background: #fff;
          color: #9f9f9f;
          padding: 8px 10px 8px 20px;
          margin: 0;
          line-height: normal;
          border-radius: 2px;
        }

        .form-control {
          display: flex;
          flex-direction: column;
        }

        .form-control:focus {
          box-shadow: none;
        }
      }

      .date:after {
        content: url(/assets/img/arrow.svg);
        position: absolute;
        z-index: 2;
        left: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
        height: 0.8rem;
        width: 1rem;
        pointer-events: none;
      }
    }

    .forum-items {
      width: 100%;
      overflow-y: auto;
      padding: 0;
      margin: 0 2rem 0 2rem;

      .forum-item {
        padding: 2rem;
        position: relative;
        .forum-header {
          width: 100%;

          img {
            max-width: 2.6rem;
            max-width: 2.6rem;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }

          p {
            font-weight: 400;
            color: #191919;
          }

          .info {
            font-weight: 400;
            color: #787878;
          }
        }

        .forum-body {
          .title {
            font-weight: 400;
            margin-bottom: 1.4rem;
            width: 96%;
            line-height: 1;
            transition: 0.5s all;
            cursor: pointer;

            &:hover {
              color: $color-blue;
            }
          }

          .text {
            font-weight: 300;
            margin-bottom: 2.2rem;
            line-height: 1.57;
            word-break: break-all;
          }
        }

        .chat {
          position: absolute;
          top: 7rem;
          left: 2rem;
          cursor: pointer;

          svg {
            width: 1.6rem;
            height: 1.6rem;
          }

          .cls-1 {
            transition: all 0.5s;
          }

          span {
            color: #787878;
            vertical-align: middle;
            transition: all 0.5s;
          }
          &.active {
            span {
              color: $color-orange;
            }
            .cls-1 {
              fill: $color-orange;
            }
          }
        }

        .chat:hover span {
          color: $color-blue;
        }

        .chat:hover .cls-1 {
          fill: $color-blue;
        }
      }
    }

    ::-webkit-scrollbar {
      width: 1rem;
    }

    ::-webkit-scrollbar-track {
      background: $color-white;
      border: 1px solid #e1e1e1;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #e1e1e1;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #bfbfbf;
    }

    .forum-item:nth-child(even) {
      background: $color-white;
    }

    .forum-item:nth-child(odd) {
      //background-color: #f5f5f5;
    }
  }
}

.forum-1 {
  .forum-content {
    background-color: $color-white;
    position: relative;

    .forum-items {
      width: 100%;
      width: 100%;
      height: 50rem;
      overflow-y: auto;
      padding: 0;
      margin: 2rem;
      .input-error-message {
        padding-top: 5px;
        padding-bottom: 5px;
        display: block;
      }
      .forum-item {
        padding: 2rem;
        position: relative;

        .forum-body {
          .forum-header {
            width: 100%;
            margin-bottom: 2.4rem;
            margin-top: 1.8rem;

            img {
              max-width: 2.6rem;
              max-width: 2.6rem;
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
              height: 2.6rem !important;
            }

            p {
              font-weight: 400;
              color: #191919;
            }

            .info {
              font-weight: 400;
              color: #787878;
            }
          }

          .title {
            font-weight: 400;
            line-height: 1;
          }
          .text {
            font-weight: 300;
            line-height: 2.2rem;
            p {
              margin-bottom: 1rem;
            }
          }
        }
      }
      .forum-item.forum-item-short {
      }
    }

    ::-webkit-scrollbar {
      width: 1rem;
    }

    ::-webkit-scrollbar-track {
      background: $color-white;
      border: 1px solid #e1e1e1;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #e1e1e1;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #bfbfbf;
    }

    .forum-item:nth-child(even) {
      background: $color-white;
    }

    .forum-item:nth-child(odd) {
      //background-color: #f5f5f5;
    }

    .forum-item-control {
      position: relative;
      padding: 2rem 0;

      .chat {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 2rem;
        cursor: pointer;

        svg {
          width: 1.6rem;
          height: 1.6rem;
        }

        .cls-1 {
          transition: all 0.5s;
        }

        span {
          color: #787878;
          vertical-align: middle;
          transition: all 0.5s;
        }
      }

      .chat:hover span {
        color: $color-blue;
      }

      .chat:hover .cls-1 {
        fill: $color-blue;
      }

      .btn-primary {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.5s ease;
        height: 3.5rem;
        width: 17rem;
        font-weight: 500;
        color: $color-white;
        letter-spacing: 0.72px;
        margin-left: 2rem;
      }

      .send-btn {
        display: none;
      }
    }

    .redactor {
      margin-top: 2rem;
      border: 0.1rem solid $color-gray;
      width: 100%;
      // height: 20.5rem;
      // height: 26rem;
      max-height: 32rem;

      .redactor-header {
        width: 100%;
        height: 2.5rem;
        border-bottom: 0.1rem solid $color-gray;
      }

      textarea {
        width: 100%;
        height: 20rem;
        border: none;
        background: transparent;
      }
    }

    .forum-item-short {
      padding: 2rem;
      position: relative;

      .forum-header {
        width: 100%;

        img {
          max-width: 2.6rem;
          max-width: 2.6rem;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
          height: 2.6rem !important;
        }

        p {
          font-weight: 400;
          color: #191919;
        }

        .info {
          font-weight: 400;
          color: #787878;
        }
      }

      .forum-body {
        .text {
          font-weight: 300;
          line-height: 1;

          p {
            margin-bottom: 0 !important;
          }
        }
      }

      .chat {
        position: absolute;
        bottom: 1rem;
        left: 2rem;
        cursor: pointer;

        svg {
          width: 1.6rem;
          height: 1.6rem;

          path {
            fill: #787878;
            transition: all 0.5s;
          }
        }

        span {
          color: #787878;
          vertical-align: middle;
          transition: all 0.5s;
        }
      }

      .chat:hover span {
        color: $color-blue;
      }

      .chat:hover svg path {
        fill: $color-blue;
      }
    }
  }
}

.validation-modal {
  .modal-content {
    height: 215px;

    overflow: hidden;
  }
}

.modal-suggest {
  .modal-dialog {
    > div {
      border-radius: 0;
      border: 0;
      padding: 0;
    }

    .modal-header {
      border: none;
      width: 100%;
      height: 4rem;
      position: relative;
      //background-color: #f5f5f5;

      button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 1.5rem;
        right: unset;
        width: 1.6rem;
        height: 1.6rem;
        cursor: pointer;
        outline: none;

        svg,
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .close {
        margin: 0;
        padding: 0;
      }
    }

    .modal-body {
      padding: 2rem;

      .form {
        width: 100%;

        div {
          text-align: left;
        }
      }

      h4 {
        text-align: right;

        span {
          font-weight: 600;
        }
      }

      .form-group {
        label {
          color: #787878;
          letter-spacing: 0.07px;
        }

        input,
        textarea {
          height: 4rem;
          border: 0.1rem solid rgba(161, 165, 192, 0.4);
          background-color: $color-white;
          width: 100%;
        }

        textarea {
          height: 18rem;
        }
      }

      .g-recaptcha {
        > div {
          margin: auto;
        }
      }
    }

    .modal-footer {
      border: 0;

      .btn-primary {
        margin: 0 1rem;
      }
    }
  }
}

.main-validation-message {
  display: flex;
  margin: auto;
  margin-bottom: auto !important;
}
#sugestTopicModal .input-error-message {
  padding-right: 0 !important;
}

@media (max-width: 578px) {
  .content-menu {
    .forum {
      position: relative;

      .badge {
        right: 14%;
      }
    }
  }
  .forum-1 .forum-content .redactor {
    max-height: 50rem;
  }
  .forum-1 .forum-content .forum-item-control .chat {
    top: 97%;
    left: 0;
  }
  .forum-1 .forum-content .forum-item-control .btn-primary {
    margin: auto;
  }
  .forum-1 .forum-content .forum-item-control {
    width: 100%;
  }
  .all-courses .filter .search-item form .date.date2 {
    margin-left: 0;
  }
  .all-courses .filter .search-item form .form-row > div {
    margin-left: 14px;
  }
  .date2 ngb-datepicker {
    min-width: 30rem !important;
    top: 50px !important;
  }
  .date2 .ngb-dp-day,
  .ngb-dp-week-number,
  .ngb-dp-weekday {
    width: 4rem !important;
    height: 4rem !important;
    line-height: 4rem !important;
  }
  .date2 [ngbDatepickerDayView] {
    width: 4rem !important;
    height: 4rem !important;
    line-height: 4rem !important;
  }
  .all-courses .filter .search-item ngb-datepicker-navigation {
    height: 40px !important;
  }
  .date2 .ngb-dp-months {
    background: #ffffff !important;
  }
  .date2 .ngb-dp-navigation-select {
    flex: 1 1 15rem;
  }
  .all-courses .filter .search-item ngb-datepicker-navigation ngb-datepicker-navigation-select select.custom-select {
    height: 2.85rem;
  }
  .all-courses .ngb-dp-arrow-btn:focus {
    outline-width: 0;
  }
  .forum .forum-content .forum-items .forum-item .forum-body .title {
    width: 88%;
  }
}
@media (max-width: 330px) {
}
