.my-courses-empty {
  padding: 0;
  z-index: 1;
  position: relative;
  width: 100%;
  h1 {
    font-weight: 500;
    margin-bottom: 20px;
    margin-right: -15px;
    color: var(--text-primary);
  }
  .entries {
    font-weight: 400;
    padding-top: 10px;
    margin-right: -15px;
    color: var(--text-primary);
    display: block;
  }
  .empty {
    background-color: var(--background-primary);
    padding: 0 30px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    flex-direction: column;
    align-items: center;
    .title {
      font-weight: 400;
      padding-bottom: 20px;
      text-align: center;
      color: var(--text-secondary);
    }
    .btn-primary {
      width: 180px;
      margin-top: 30px;
    }
    .close {
      position: absolute;
      top: 12px;
      left: 12px;
      width: 16px;
      height: 16px;
      cursor: pointer;
      svg,
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.my-courses-info {
  & > .container {
    & > .row.justify-content-right {
      & > div {
        width: 100%;
        .course-message {
          background-color: var(--background-primary);
          display: flex;
          align-items: center;
          justify-content: center;
          .title {
            width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .my-courses-empty {
    h1 {
      margin-right: 0;
    }
    .entries {
      margin-right: 0;
    }
  }
}
