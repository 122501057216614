:root {
  --direction: rtl;
}

@import "colors";
@import "sizes";
@import "icons";
@import "buttons";
@import "form-field";
@import "label";
