.point-block {
  max-width: 355px;
  margin-top: 10px;

  &__title,
  h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    margin-bottom: 8px;
  }

  &__sub-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-secondary);
    margin-bottom: 22px;
    display: block;
    text-align: center;
  }

  &__points,
  &__points > ul {
    display: flex;
    flex-direction: column;
    gap: 22px;

    &__item,
    li {
      position: relative;
      padding-inline-start: 48px;
      list-style: none;
      font-size: 16px;
      line-height: 20px;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      &:before {
        content: "";
        background: url("../../assets/icons/check-circle.svg") #fff no-repeat center;
        width: 22px;
        height: 22px;
        border-radius: 12px;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
      }
    }
  }

  &__points-dark {
    * {
      color: #FFFFFF;
    }
  }

  &--rtl {
    .point-block__points li:before {
      right: 0;
      left: auto;
    }
  }
}
