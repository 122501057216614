@import "../var";

.evaluation-courses {
  .row {
    .content-menu.evaluation {
      background-color: $new-primary-violet-light;

      ul {
        height: 50px;

        li {
          display: flex;
          align-items: flex-end;
          cursor: pointer;
          white-space: nowrap;
        }
      }
    }
  }

  .evaluation-table {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;

    &.homework {
      .name {
        p {
          //@errorpadding-top: 25px;
        }
      }
    }
    &.hide {
      display: none;
    }

    &.show {
      display: flex !important;
    }
    .table-row:not(:first-child) {
      border-bottom: $light-gray-border;
    }
    .table-row {
      display: flex;
      position: relative;
      padding-left: 25px;
      padding: 25px 0;
      gap: 20px;

      //&::after {
      //    content: '';
      //    position: absolute;
      //    left:24px;
      //    right:24px;
      //    bottom: 0;
      //    height: 1px;
      //    background-color: #e8e8e8;
      //}

      &:first-child::after {
        height: 0;
      }

      .cell {
        display: flex;
        text-align: center;
        line-height: 1;
        vertical-align: middle;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        color: var(--text-primary);

        p,
        a {
          width: 100%;
          line-height: 1.2;
          display: inline-flex;
          justify-content: flex-start;

          &.category {
            color: var(--text-primary);
            font-weight: 500;
          }

          &.category-name {
            font-weight: 300;
          }
        }
      }

      .five {
        width: 64%;
        text-align: right;
        //padding-right:24px;
        justify-content: flex-start;
      }

      .first {
        width: 12%;
        display: flex;
        justify-content: flex-end;
      }

      .second {
        width: 13%;
      }
      .three,
      .four {
        width: 12%;
      }

      .grade {
        span {
          width: auto;
          height: 30px;
          text-align: center;
          line-height: 30px;
        }
        span.red {
          //width: inherit;
          color: $status-pass-complexity-red-color;
          //background-color: rgba(225, 11, 11, .3);
        }
        span.green {
          //width: inherit;
          color: $new-progress-bar-course-color;
          //background-color: rgba(23, 101, 5, .3);
        }
      }
    }

    .name {
      p {
        text-align: right;
        line-height: 1;
        padding-top: 22px;
      }
    }

    .header-row-table {
      min-height: 35px;

      .cell {
        color: var(--text-secondary) !important;
      }
    }
  }
}

.evalution-mobile {
  .header-row-table {
    line-height: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .table-row-mobile {
    .row {
      border-bottom: var(--color-border-inner) 1px solid;
      padding: 7px 0;
      display: flex;
      align-items: center;
      span {
        padding: 0 !important;
      }
    }
    span:first-child {
      color: var(--text-secondary);
    }

    .grade {
      span {
        min-width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        display: inline-block;
        font-weight: 400;
      }
      span.red {
        color: $status-pass-complexity-red-color;
        //background-color: rgba(225, 11, 11, .3);
      }
      span.green {
        color: $new-progress-bar-course-color;
        //background-color: rgba(23, 101, 5, .3);
      }
    }
  }
}

.elevation-message {
  background-color: var(--background-primary);
  color: var(--text-primary);
  //padding: 80px;
  width: 100%;

  .empty {
    background-color: var(--background-primary);
    //box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.2);
    //box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.35);
    padding: 50px 30px 0;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: auto;

    .title {
      font-weight: 400;
      padding-top: 20px;
      padding-bottom: 20px;
      text-align: center;
      width: 100%;
      color: var(--text-secondary);
    }
    .btn-primary {
      width: 180px;
    }
    .close {
      position: absolute;
      top: 12px;
      left: 12px;
      width: 16px;
      height: 16px;
      cursor: pointer;
      svg,
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
@media (max-width: 768px) {
  .evaluation-courses {
    .evaluation-table {
      .table-row {
        //.five {
        //  width: 40%;
        //}

        .first,
        .second,
        .three,
        .four {
          width: 15%;
        }
      }
    }
  }
}
//@media (max-width: 439px) {
//  .evaluation-table {
//    max-width: 205px !important;
//  }
//}
@media (max-width: 578px) {
  .pagination {
    margin-bottom: 10px;
  }
  .evalution-mobile {
    p {
      text-align: center;
      color: $color-dark;
    }
  }
  .pr-2 {
    padding-right: 0 !important;
  }
  .pl-2 {
    padding-left: 0 !important;
  }
  .evaluation-table {
    max-width: 90%;
  }
  .content-menu.evaluation {
    width: 100%;
    padding: 0 15px;

    ul {
      flex-wrap: wrap;

      li {
        width: 100%;
        padding: 0;

        a,
        span {
          width: 100%;
          text-align: center;

          &:before {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 4px;
            background-color: $color-gray;
            transition: $transition;
            z-index: 1;
          }

          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 0;
            height: 4px;
            background-color: $color-orange;
            transition: $transition;
            z-index: 2;
          }

          &:hover,
          &.active {
            transition: $transition;

            &:after {
              width: 100%;
              transition: $transition;
            }
          }
        }
      }
    }
  }

  .evaluation-courses {
    .evaluation-table {
      .name {
        justify-content: center;

        p {
          padding-top: 15px;
          padding-right: 0;
        }
      }
    }
  }
  .elevation-message {
    //padding: 80px 30px;
    .empty {
      padding: 20px 10px;
    }
  }
}

.pagination-side {
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 200px;

  #evaluation-table-1,
  #evaluation-table-2,
  #evaluation-table-3 {
    .evalution-mobile {
      display: block;
    }
  }

  .hide-arrow {
    display: none;
  }

  .pagination {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 29px 0;
    line-height: 1;
    align-items: center;
    border-bottom: var(--color-border-inner) 1px solid;

    div {
      color: $new-primary-blue;
      display: flex;
      align-items: center;
    }

    #prev1,
    #next1,
    #prev2,
    #next2,
    #prev3,
    #next3 {
      position: absolute;
    }

    #next1,
    #next2,
    #next3 {
      padding-right: 10px;
      left: 0%;
    }

    #prev1,
    #prev2,
    #prev3 {
      padding-left: 10px;
      right: 0%;
    }
  }

  .visibl {
    display: block !important;
  }
}
