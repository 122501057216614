@import "@airy-ui/cdk";
@import "@airy-ui/typography";

@include air-theme("wl") {
  @include air-default-titles;
  @include air-tp(h5, 18px, 16px, 500);
  @include air-tp(text1, 20px, 16px, 400);
  @include air-tp(text2, 16px, 14px, 400);
  @include air-tp(text3, 14px, 14px, 400);

  .air-button {
    &.size-xxl {
      --button__height: 56px;
    }
  }

  &:not(.dark-theme) {
    yeda-footer,
    yeda-bundle-navigation,
    yeda-course-navigation {
      --background-secondary: #f6f9ff;
      --color-primary-light: #f6f9ff;
    }

    thead:not(.mat-calendar-table-header),
    .contact input,
    .contact textarea {
      background-color: var(--color-background-secondary);
    }
  }

  // auth roles
  --auth-role-backgraound-color: #FFFFFF;
  --auth-role-border-color: #E8EAF5;
  --auth-role-search-backgraound-color: #FFFFFF;
  --auth-role-shadow-color: #A1A5C024;
  --auth-role-header-backgraound-color: #F6F9FF;
  // -----

  // Subscriptions front
  --subscriptions-table-backgraound-color: #FFFFFF;
  --subscriptions-table-border-color: #F4F5F8;
  --subscriptions-table-header-backgraound-color: #EBEEFF;
  --subscriptions-user-table-header-backgraound-color: #F6F9FF;
  --subscriptions-user-table-border-color: #F4F5F8;
  --subscriptions-table-header-text-color: #A1A5C0;
  --subscriptions-status-active: #D6F0DD;
  --subscriptions-status-waiting: #D8E4FC;
  --subscriptions-status-cancelled: #E8EAF5;
  --status-cancelled-date: #A1A5C0;
  --subscriptions-status-warning: #F0D6D8;
  // -----

  // Header
  --header-search-bar: #F3F5FF;

  //---
  --certificate-about-border: #A1A5C00F;
  --admin-backgraound-color: #f6f9fe;
  --accounts-list-border-color: #e8eaf5;
  --text-button-color: #ffffff;
  --coupon-input-background-color: #ffffff;
  --coupon-pits-color: #ffffff;
  --black-white-color: #000000;
  --certificate-bg-color: #ffffff;
  --certificate-header-cell: #8b8ea4;
  --section-title-border-color: #e8eaf5;
  --course-content-info: #8b8ea4;
  --color-primary-10: color-mix(in srgb, var(--color-primary), #fff 90%);
  --color-primary-12: color-mix(in srgb, var(--color-primary), #fff 88%);
  --course-content-background-color: var(--color-primary-10);
  --course-content-border-color: #e8eaf5;
  --mat-expansion-panel-color: #fafafc;
  --support-request-form-icon: var(--color-primary);
  --footer-text-line: #e8eaf5;
  --footer-text-background: #f2f4fa;
  --header-menu-hover: #f6f6f9;
  --show-more-categories: #ffffff;
  --background-terms: #d3d3d3;
  --no-image-frist-color: #e8eaf5;
  --no-image-second-color: #ffffff;
  --category-mat-menu-background-color: #fff;
  --category-mat-menu-color: #000;
  --category-button-cansel: #fff;
  --category-button-cansel-hover: var(--color-primary-light);
  --category-pin: #d9dbe6;
  --category-other-categories-background: var(--color-primary-light);
  --order-block: #a1a5c026;
  --category-chip: #a1a5c026;
  --category-block-title: #8b8ea4;
  --payment-tag-color: #6d6d71;
  --payment-button-background-disabled: #e8eaf5;
  --payment-button-border-disabled: #d5d9ef;
  --payment-button-text-disabled: #a1a5c0;
  --payment-enter-password-block: #f6f9ff;
  --payment-border-color: #a1a5c0;
  --payment-include-info-icon: #a1a5c0;
  --thank-you-text: #6d6d71;

  .thank-you-content__left__text {
    p {
      color: var(--thank-you-text);
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.25px;
    }
  }
}
