.suggested-courses {
  margin: 0;
  margin-top: 50px;

  .title {
    font-weight: 400;
  }

  .view-all {
    font-weight: 400;
    transition: $transition;

    &:hover {
      color: $color-blue-hover;
    }
  }
}

.col-2-custom {
  flex: 0 0 20%;
  max-width: 20%;
  position: relative;
  min-height: 1px;
  padding: 10px;

  .item {
    //padding: 15px;
    background-color: white;
    box-shadow: $box-shadow;
    min-height: 100%;

    &:hover {
      // transition: $transition;
      box-shadow: $new-shadow-block;
    }

    .img-container {
      position: relative;
      width: 100%;
      height: 100%;
      max-height: 185px;
      justify-content: center;
      display: flex;
      .logo {
        background-color: $color-white;
        height: 40px;
        width: 40px;
        border: 1px solid #d1d1d1;
        border-radius: 2px;
        position: absolute;
        left: 1.7rem;
        bottom: 0;
        transform: translateY(50%);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 5;

        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: contain;
        }
      }

      .img {
        width: 100%;
        position: relative;
        height: 185px;
        max-width: 185px;
        overflow: hidden;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          background-color: #026d92;
          opacity: 0.05;
          z-index: 1;
        }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }

    .desc {
      //margin-top: 2rem;
      padding: 7.5px 12px 12px 12px;

      .start-data {
        color: $course-category-price-color !important;
      }
      > span {
        width: 100%;
        display: block;
        color: #292929;
        font-weight: 400;
        line-height: 22px;

        &.name {
          color: $color-dark;
          font-weight: 600;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &.price {
          color: $color-blue;
          font-weight: 600;
          margin-top: 14px;

          .old-price {
            color: #cacbcc;
            position: relative;
            margin-left: 10px;
            display: inline-block;
            text-decoration: line-through;
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .col-2-custom {
    .item {
      .img-container .img {
        height: 142px;
      }
    }
  }
}
@media (max-width: 992px) {
  .col-2-custom {
    flex: 0 0 25%;
    max-width: 25%;
    position: relative;
    min-height: 1px;
    padding: 10px;
    .item {
      .img-container .img {
        height: 130px;
      }
    }
  }
}

@media (max-width: 768px) {
  .col-2-custom {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
    position: relative;
    min-height: 1px;
    padding: 10px;
  }
}

@media (max-width: 578px) {
  .col-2-custom {
    flex: 0 0 50%;
    max-width: 50%;
    position: relative;
    min-height: 1px;
    padding: 10px;
    .item {
      .img-container {
        .img {
          max-width: 100%;
          height: 180px;
          img {
            width: 100% !important;
          }
        }
      }
    }
  }
}
@media (max-width: 400px) {
  .col-2-custom {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;
    min-height: 1px;
    //padding: 10px 20px;
    padding: 0 17px 15px 16px;
    .item {
      .img-container {
        .img {
          img {
            width: 100% !important;
          }
        }
      }
    }
  }
}
