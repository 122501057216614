router-outlet {
  display: none !important;
}

*, *::before, *::after {
  box-sizing: border-box;
}

html:focus-within {
  scroll-behavior: smooth
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

body {
  min-height: 100vh;
  text-rendering: geometricPrecision;
  line-height: 1.5
}


a:not([class]) {
  text-decoration-skip-ink: auto
}

img, picture {
  max-width: 100%;
  display: block
}


input, button, textarea, select {
  font: inherit
}


@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto
  }

  *, *::before, *::after {
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: .01ms !important;
    scroll-behavior: auto !important
  }
}

