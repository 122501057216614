.profile-update {
  .content-menu {
    h2 {
      color: #191919;
      font-weight: 500;
      letter-spacing: -0.3px;
    }

    ul li a {
      font-weight: 400;
    }
  }

  .profile-update-info {
    .container {
      background-color: $color-white;
      padding: 20px 30px;
      position: relative;

      .form {
        label {
          color: $color-dark;
          line-height: 15px;
          letter-spacing: 0.07px;
        }

        input {
          height: 48px;
          border: 1px solid #d1d1d1;
          background-color: $color-white;
        }

        .form-row {
          margin-left: -15px;
          margin-right: -15px;
        }

        .form-row > .col,
        .form-row > [class*="col-"] {
          padding-right: 15px;
          padding-left: 15px;
        }

        .contact-details {
          color: $color-dark;
          margin: 30px 0 21px;
        }

        .form-check-inline {
          margin: 30px 0 16px;

          .label {
            margin-right: 10px;
          }
        }

        .btn-primary {
          width: 180px;
        }

        .closed {
          color: $color-dark;
          //margin-right: 40px;
        }
      }
    }
  }
}
@media (max-width: 1200px) {
  .profile-update {
    .profile-update-info {
      padding: 20px 15px;
    }
  }
}

@media (max-width: 1200px) {
  .profile-update {
    .profile-update-info {
      .container {
        .form {
        }
      }
    }
  }
}
