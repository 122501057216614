@import "@airy-ui/cdk";
@import "@airy-ui/typography";

@include air-theme("dark-theme") {
  @include air-colors(
    $primary: (
      main: #fff,
      dark: #1b1b1e,
      light: #545456,
      shades: (
        50: #545456,
      ),
    ),
    $background: #1b1b1e,
    $background-secondary: #2d2d30,
    $text: #fff,
    $text-secondary: #a1a5c0,
    $borders: #2d2d30,
    $contrast: (
      dark: #545456,
      light: #fff,
    ),
    $success: (
      main: #0b9785,
      dark: #1d645a,
      light: #79d0c5,
      shades: (
        50: #79d0c5,
      ),
    ),
    $disabled: (
      main: #b2b3b7,
      dark: #545456,
      light: #cfcfd3,
    )
  );

  &--override-colors {
    .about-course-content,
    yeda-editable .text,
    yeda-video-banner,
    i-thread-text,
    .lesson,
    .contact,
    .information,
    .terms-content,
    .content-terms,
    i-page .ck-content,
    i-content .text,
    yeda-learn {
      p,
      span,
      strong,
      b,
      ul,
      li,
      h1,
      h2,
      h3,
      h4,
      h5 {
        color: #fff !important;
        background-color: inherit !important;
      }
    }
  }

  // materials
  --mdc-filled-button-label-text-color: #ffffff !important;
  --mat-menu-item-hover-state-layer-color: #A1A5C066 !important;
  --mdc-switch-handle-surface-color: #A1A5C066 !important;
  // -----

  // auth roles
  --auth-role-backgraound-color: #1B1B1E;
  --auth-role-border-color: #545456;
  --auth-role-search-backgraound-color: transparent;
  --auth-role-shadow-color: #1B1B1E24;
  --auth-role-header-backgraound-color: #3F4042;
  // -----

  // Subscriptions admin
  --subscriptions-table-backgraound-color: #1B1B1E;
  --subscriptions-table-border-color: #3F4042;
  --subscriptions-table-header-backgraound-color: #3F4042;
  --subscriptions-user-table-header-backgraound-color: #3F4042;
  --subscriptions-user-table-border-color: #3F4042;
  --subscriptions-table-header-text-color: #A1A5C0;
  --subscriptions-status-active: #153C21;
  --subscriptions-status-waiting: #172A4F;
  --subscriptions-status-cancelled: #3F4042;
  --status-cancelled-date: #FFFFFF;
  --subscriptions-status-warning: #3C1517;
  // -----

  // Header
  --header-search-bar:  #3F4042;

  //---

  --category-chip-change-order: transparent;
  --certificate-about-border: #A1A5C01A;
  --admin-backgraound-color: #2d2d30;
  --text-button-color: #FFFFFF;
  --accounts-list-border-color: #49494b;
  --color-current-contrast: #fff !important;
  --coupon-input-background-color: #1B1B1E;
  --yeda-background-primary: #2D2D30;
  --yeda-background-secondary: #1b1b1e;
  --yeda-background-tertiaty: #3F4042;
  --yeda-text-primary: #fff;
  --yeda-text-secondary: #a1a5c0;
  --black-white-color: #ffffff;
  --section-title-border-color: #3F4042;
  --certificate-bg-color: #2D2D30;
  --certificate-header-cell: #A1A5C0;
  --coupon-pits-color: #1b1b1e;
  --course-content-info: #A1A5C0;
  --color-primary-16: color-mix(in srgb, var(--color-primary), #000 84%);
  --course-content-background-color: var(--color-primary-16);
  --course-content-border-color: #3F4042;
  --mat-expansion-panel-color: #232328;
  //--support-request-form-icon: #1B1B1E;
  --support-request-form-icon: #fff;
  --footer-text-line: #545456FF;
  --footer-text-background: var(--background-secondary);
  --header-menu-hover: #494a4f;
  --show-more-categories: #3F4042;
  --background-terms: var(--background-secondary);
  --no-image-frist-color: #2d2d30;
  --no-image-second-color: #4f5053;
  --category-mat-menu-background-color: #3F4042;
  --category-mat-menu-color: #fff;
  --category-button-cansel: #2D2D30;
  --category-button-cansel-hover: var(--color-primary-dark);
  --color-admin-header-button: #fff;
  --category-pin: #545456;
  --category-other-categories-background: #2d2d30;
  --order-block: #545456FF;
  --category-chip: transparent;
  --category-chip-course-to-order: #e0e0e0;
  --category-block-title: #fff;
  --teacher-card-border: transparent;
  --teacher-card-shadow: #ffffff33;
  --payment-tag-color: #bbbbbb;
  --payment-button-background-disabled: #46464d;
  --payment-button-border-disabled: #5d5d65;
  --payment-button-text-disabled: #727487;
  --payment-enter-password-block: #2d2d30;
  --payment-border-color: #a1a5c0;
  --payment-include-info-icon: #ffffff;
  --thank-you-text: #bbbbbb;
  --header-height: 76px;
  --color-light: #545456;
  --color-primary-30: var(--background-secondary) !important;
  --color-yeda-logo-lms: #6c74ae;
  --color-yeda-logo-org: #a1a5c0;
  --background__primary: var(--background-primary);
  --tab-links-container__border-color: var(--color-accent);
  --item-box-shadow: none;
  --item-box-shadow-hover: #4f5053;
  --nav-item__background_active: #4f5053;
  --color-border: #2d2d30;
  --color-border-inner: #49494b;
  --delimiter__background-color: #49494b;
  --color-background-secondary: #49494b;
  --modal__background: var(--background-secondary);
  --button__color_outline: var(--color-primary);

  --input__background: #545456;
  --ck-color-base-background: var(--input__background);
  --ck-color-base-border: var(--input__background);
  --select__background: #545456;
  --option__background: #3f4042;

  --toggle-slider__background: var(--color-accent-dark);
  --toggle-slider__border: var(--color-accent-dark);
  --toggle__background_active: var(--color-accent);
  --toggle__background: var(--color-accent);

  --select-arrow__color_opened: #e0e1ee;
  --list-item__delimiter-color: var(--delimiter__background-color);

  air-toggle:not(.active) {
    --toggle-slider__background: #8d8e92;
    --toggle-slider__border: #8d8e92;
    --toggle__background_active: var(--input__background);
    --toggle__background: var(--input__background);
  }

  .nav,
  .navigation-side .navigation .list-group cdk-accordion-item a.active,
  .lesson .lesson-body .description,
  .course-practice .practice-part .course-practice-body .description,
  i-page .navigation,
  .survey .survey-body .description {
    --color-primary-light: #2D2D30;
    --color-primary-50: #545456;
  }

  .day-has-passed {
    --color-accent-50: #3f4042;
  }

  .mode-tabs:before {
    --tab-links-container__border-color: var(--delimiter__background-color);
  }

  i-pagination button,
  .load-more {
    color: var(--contrast-light) !important;
    background-color: var(--contrast-dark) !important;

    &:hover {
      background-color: var(--color-primary-dark) !important;
      color: var(--color-primary-dark-contrast) !important;
    }
  }

  .dropdown-item__text:hover {
    --color-primary-30: #545456;
  }

  .dropdown-content {
    box-shadow: 0 3px 11px -3px #1b1b1e;
  }

  .back-call-form {
    --icon__color: #6c74ae;
  }

  .fixed-call-form {
    --color-primary: #a1a5c0;
  }

  .form-group {
    input,
    textarea {
      border: 0;
    }
  }

  air-option {
    &:hover,
    &.active {
      background: var(--background-secondary);
    }
  }

  .air-textarea {
    background: var(--input__background);
  }

  .air-select-panel {
    border: 1px solid var(--option__background);

    .active-select-border {
      border: 0;
    }
  }

  .cdk-overlay-pane {
    margin-left: 1px;
  }

  .air-button {
    &.outline {
      &:hover {
        border-color: transparent;
        background-color: var(--color-primary-dark);
        color: var(--color-primary-dark-contrast);
      }
    }
  }

  .view--scroll__to--top {
    --background-secondary: #000000;
    border: 0;

    &:hover {
      background-color: #545456;
    }

    path {
      fill: #fff;
    }
  }

  .visually-impaired {
    --color-primary: var(--text-secondary);
  }

  .content-menu ul li a,
  i-page .navigation ul:not(.names-list) li > a {
    font-weight: 400;

    &.active,
    &:hover {
      --color-primary: #000;
      font-weight: 400;
    }
  }

  i-page .navigation ul.names-list li > a {
    &.active,
    &:hover {
      --color-primary: #6c74ae;
    }
  }

  yeda-statistics {
    --h2__font-weight: 400;
    min-height: 118px;

    @media (min-width: 900px) {
      padding: 10px 0;
    }

    .delimiter {
      --border-color: #49494b;
    }
  }

  .question-pagination ul li a {
    --color-primary: #49494b;
  }
}
