@import "@airy-ui/cdk";
@import "@airy-ui/typography";

@include air-theme("admin") {
  @include air-tp(h1, 32px, 24px, 500);
  @include air-tp(h2, 24px, 18px, 500);
  @include air-tp(h3, 18px, 16px, 500);
  @include air-tp(h4, 16px, 14px, 500);
  @include air-tp(h5, 14px, 14px, 500);

  @include air-tp(text1, 16px, 14px, 400);
  @include air-tp(text2, 14px, 14px, 400);
  @include air-tp(text3, 14px, 14px, 400);

  // auth roles
  --auth-role-backgraound-color: #FFFFFF;
  --auth-role-border-color: #E8EAF5;
  --auth-role-search-backgraound-color: #FFFFFF;
  --auth-role-shadow-color: #A1A5C024;
  --auth-role-header-backgraound-color: #F6F9FF;
  // -----

  // Subscriptions admin
  --subscriptions-table-backgraound-color: #FFFFFF;
  --subscriptions-table-border-color: #F4F5F8;
  --subscriptions-table-header-backgraound-color: #EBEEFF;
  --subscriptions-user-table-header-backgraound-color: #F6F9FF;
  --subscriptions-table-header-text-color: #A1A5C0;
  --subscriptions-status-active: #D6F0DD;
  --subscriptions-status-waiting: #D8E4FC;
  --subscriptions-status-cancelled: #E8EAF5;
  --status-cancelled-date: #A1A5C0;
  --subscriptions-status-warning: #F0D6D8;
  // -----

  --admin-backgraound-color: #f6f9fe;
  --category-button-cansel-hover: var(--color-primary-light);
  --color-admin-header-button: var(--color-primary);
  --no-image-frist-color: #E8EAF5;
  --no-image-second-color: #FFFFFF;
  --category-mat-menu-background-color: #fff;
  --category-mat-menu-color: #000;
  --category-button-cansel: #fff;
  --category-pin: #D9DBE6;
  --category-other-categories-background: var(--color-primary-light);
  --order-block: #A1A5C026;
  --category-chip: #A1A5C026;
  --category-block-title: #8B8EA4;
  --mat-expansion-panel-color: #fafafc;
  --course-content-border-color: #E8EAF5;
  --color-primary-10: color-mix(in srgb, var(--color-primary), #fff 90%);
  --color-primary-12: color-mix(in srgb, var(--color-primary), #fff 88%);
  --course-content-background-color: var(--color-primary-10);
  --black-white-color: #000000;
  --category-chip-change-order: #FFF;
  --category-chip-course-to-order: #A1A5C026;
}
