@import "../var";

.form-group {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 15px;

  input {
    height: 48px;
  }

  textarea {
    resize: none;
    height: 120px;
  }

  input,
  textarea {
    padding: 15px;
    border: none;
    width: 100%;
    line-height: 24px;
    color: var(--text-primary);
    background: var(--input__background);
    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: var(--text-secondary);
    }

    ::-moz-placeholder {
      /* Firefox 19+ */
      color: var(--text-secondary);
    }

    :-ms-input-placeholder {
      /* IE 10+ */
      color: var(--text-secondary);
    }

    :-moz-placeholder {
      /* Firefox 18- */
      color: var(--text-secondary);
    }
  }

  .input-error-message {
    width: calc(100% - 110px);
    color: $input-error-color;
    margin-top: 3px;
  }

  .pr-22 {
    padding-right: 22%;
  }
}

.form-check-inline {
  margin: auto;
  margin-left: 0;
  text-align: left;
  label {
    margin-bottom: 0;
    display: inline-flex;
    flex-direction: row-reverse;
    align-items: center;
    cursor: pointer;
    .label {
      margin-right: 10px;
      margin-left: 10px;
      color: var(--text-primary);
    }

    .checkbox {
      display: none;
    }

    .checkbox-own {
      cursor: pointer;
      position: relative;
      width: 16px;
      height: 16px;
      border: 0.1rem solid #e1e1e1;
      background-color: $color-white;
    }

    .checkbox:checked + .checkbox-own::before {
      content: "";
      display: block;
      position: absolute !important;
      background: $new-primary-blue;
      top: -0.1rem;
      right: -0.1rem;
      background: var(--color-primary);
      height: 16px;
      width: 16px;
    }
  }
}

@media (max-width: 578px) {
  .form-group {
    .pr-22 {
      padding-right: 0;
    }
  }
}
