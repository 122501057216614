@use "@angular/material" as mat;
@import "themes";
@mixin global-init() {
  html,
  body {
    .empty-badge {
      .mat-badge-content {
        color: transparent;
        user-select: none;
      }
    }

    .yeda-no-border-radius {
      --mdc-dialog-container-shape: 0;
      border-radius: 0;
    }

    .yeda-invisible-backdrop {
      background-color: transparent !important;
    }
  }

  .mat-badge-primary {
    @include mat.badge-color($theme-light, $color-variant: primary);
  }
}
