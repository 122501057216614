.cdk-overlay-container {
  z-index: 1002 !important;

  & > div[dir] {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .modal-panel {
    z-index: 9999;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .modal-content {
    width: 370px;
    max-width: 100%;
  }

  .modal-body {
    width: 100%;
    display: block !important;
    padding: 0;
  }

  .cdk-overlay-backdrop:not(.mat-overlay-transparent-backdrop) {
    background: rgba(#000, 0.5);
  }
}

.modal-outer {
  position: relative;
}

.modal {
  &-dialog {
    .modal-content {
      background: var(--background-secondary);
    }
  }

  &__content {
    .content-terms {
      padding-bottom: 50px;
      ul,
      ol {
        padding-right: 20px;
        padding-bottom: 15px;
      }

      p {
        padding-bottom: 15px;
      }

      table {
        tr,
        td {
          border: 1px solid var(--border-color-table);
        }
      }

      a {
        text-decoration: underline;
        color: var(--color-primary);
      }
    }
  }

  &__sticky-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background: var(--text-button-color);
    padding: 15px 30px;
    box-shadow: 0px -5px 20px 0 transparentize(#dee0ec, 0.45);

    &__btns {
      display: flex;
      justify-content: space-between;

      &--flip {
        flex-direction: row-reverse;
      }

      &--cancel,
      #cancel-payment {
        background: #fff;
        border: 1px solid #d5d8ef;
        color: var(--color-danger);
        transition: 200ms ease-in-out;

        &:hover {
          background: #fdedef;
          border: 1px solid #fdedef;
        }
      }
    }
  }
}
