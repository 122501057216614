@import "../../scss/var";

.course-learning {
  > .col {
    width: auto;
  }
  .container {
    position: relative;
    padding: 0 20px;

    > .row {
      margin-inline-start: -20px;
      margin-inline-end: -20px;
      padding-top: 40px;
      padding-inline-end: 20px;

      @media (max-width: 578px) {
        padding-inline-end: 0;
        padding-top: 50px;
      }
    }
  }

  @media (min-width: 990px) {
    .course-about-head {
      &.no-border-right {
        border-right: 0 !important;
      }
      &.no-border-left {
        border-left: 0 !important;
      }
    }
  }

  .course-about-head {
    border: 1px solid var(--color-border-inner);
    margin: 0;
    padding: 20px;

    @media (max-width: 578px) {
      border: 0;
    }

    .course-about-head-info {
      align-content: center;
      display: flex;
      flex-wrap: wrap;
    }

    h1 {
      font-weight: 400;
      line-height: 1;
      margin-bottom: 15px;
      width: 100%;
      word-break: break-word;
    }

    .sub-title {
      color: #787878;
      font-weight: 400;
      line-height: 1;
      margin-bottom: 30px;
      width: 100%;
    }

    .img {
      img {
        width: 100%;
        height: auto;
        -o-object-fit: cover;
        object-fit: cover;
        max-width: -webkit-fit-content;
        max-width: -moz-fit-content;
        max-width: fit-content;
        max-height: 150px;
      }
    }

    .info-lesson {
      width: 100%;

      p {
        font-weight: 400;
        line-height: 1;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        transition: $transition;

        &:hover {
          transition: $transition;
          color: $color-blue;
        }
      }
    }
  }

  .course-about-body,
  .course-about .course-about-body {
    //padding-top: 20px;
    //padding-right: 20px;
    padding: 16px;

    h2 {
      font-weight: 400;
      margin-bottom: 15px;
      line-height: 1;
    }

    p {
      font-weight: 300;
      line-height: 22px;
      padding-bottom: 15px;
    }

    ul,
    ol {
      padding-right: 20px;
      padding-bottom: 15px;
    }

    table {
      tr,
      td {
        border: 1px solid var(--border-color-table);
      }
    }

    a {
      text-decoration: underline;
      color: var(--color-primary);
    }

    .orange-dot-ul ul li,
    ul li {
      max-width: 100%;
      font-weight: 300;
      margin-bottom: 15px;
      line-height: 1;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.lesson-body {
  ul,
  ol {
    padding-right: 20px;
    padding-bottom: 15px;
  }

  p {
    padding-bottom: 15px;
  }

  table {
    tr,
    td {
      border: 1px solid var(--color-border);
    }
  }

  a {
    text-decoration: underline;
    color: var(--color-primary);
  }
}

.lesson-body .description {
  table {
    tr,
    td {
      border: 1px solid var(--border-color-table);
    }
  }
}

.lesson-body ol,
.lesson-body ul {
  margin: 24px 0 !important;
}

.lesson-body ul li {
  p {
    direction: rtl !important;
  }
}
.lesson-body ol li {
  p {
    direction: rtl !important;
  }

  * {
    color: black !important;
  }
}

yeda-with-sidebar {
  width: 100%;
  max-width: 100vw;
}

yeda-with-sidebar > section > .container {
  position: relative;
  padding: 0 20px;
}

yeda-with-sidebar > section > .container > .row {
  margin-left: -20px;
  margin-right: -20px;
}

yeda-about-course {
  width: 100%;
}

yeda-course-survey,
yeda-course-lesson,
app-course-exam-result,
yeda-course-exam,
yeda-practice-result,
yeda-course-practice {
  display: flex;
  flex: 1 1 100%;
  > div {
    flex: 1 1 100%;
    display: flex;
  }
  .left-content {
    width: 100%;
  }
}
.row-sidebar {
  min-height: 45vh;
}

.additional-info {
  width: 100%;

  span {
    width: 100%;
    display: block;
    color: #787878;
    font-weight: 400;
    line-height: 22px;
  }

  span.price {
    color: #2f96e2;
    font-weight: 600;
    // margin-top: 20px;
    margin-bottom: 15px;
    line-height: 18px;

    .old-price {
      width: auto;
      color: #cacbcc;
      position: relative;
      margin-inline-end: 10px;
      display: inline-block;
      text-decoration: line-through;
    }
  }

  span.start-data {
    margin-bottom: 15px;

    span {
      display: inline-block;
      width: auto;
    }
  }
}
.course-descr {
  font-weight: 300;
  line-height: 22px;
  margin-bottom: 15px;

  .course-descr-title {
    display: block;
  }

  .course-descr-text {
  }
}
.associated-files {
}
.about-lesson .additional-info span.price {
  margin-bottom: 0;
  line-height: 1;
}
.about-lesson .additional-info span {
  line-height: 1;
}
@media (max-width: 768px) {
  .additional-info span.start-data {
    margin-bottom: 1px;
  }
  .additional-info span.price {
    //margin-bottom: 1px;
  }

  table {
    table-layout: fixed;
    overflow-x: auto;

    tbody {
      display: block;
      overflow: auto;
    }
  }
}
@media (max-width: 578px) {
  .course-about {
    .course-about-head {
      //border: 1px solid #e8e8e8!important;
    }

    .course-about-head-info {
      order: 2;
    }

    .course-about-head-img {
      order: 1;

      .img {
        justify-content: center !important;

        img {
          max-width: 320px;
        }
      }
    }
  }
  yeda-with-sidebar > section > .container {
    padding: 0;

    > .row-sidebar {
      margin: 0;
    }
  }
}
@media (max-width: 400px) {
  .course-about {
    .course-about-head-img {
      .img {
        img {
          max-width: 100%;
        }
      }
    }
  }
}
