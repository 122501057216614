@import "../../scss/var";

.teaching {
  .container {
    padding: 2rem;
    position: relative;

    .teaching-header {
      margin-right: -2rem;
      max-width: 870px;
      width: inherit;
      > div {
        width: 100%;
      }
      .photo-name-pos {
        align-items: flex-start;
      }
      .logo {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        margin-left: 2rem;
        display: flex;
        flex-wrap: wrap;

        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      .short-desc {
        color: $color-dark;
        margin-top: 15px;
        white-space: pre-wrap;
        width: inherit;
        line-height: 1.7;
      }

      .inform {
        margin-top: 29px;
        .title-name {
          color: $color-dark;
          line-height: 1;
        }
        width: calc(100% - 14rem);
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        .positions {
          width: 100%;
          color: $new-primary-blue;
          line-height: 1;
          margin-top: 8px;
        }

        h1.title-name {
          color: $color-dark;
          font-weight: 600;
          line-height: 1;
          transition: $transition;
        }

        .send-email {
          transition: $transition;
          color: $color-dark;

          span {
            font-weight: 400;
          }

          svg {
            max-width: 2rem;
            max-height: 1.5rem;
            fill: $color-blue;
          }

          &:hover {
            color: $color-blue-hover;

            svg {
              fill: $color-blue-hover;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 360px) {
  .modal {
    .modal-dialog {
      .modal-content {
        max-width: 360px;
      }
    }
  }
}
@media (max-width: 340px) {
  .modal {
    .modal-dialog {
      .modal-content {
        max-width: 340px;
      }
    }
  }
}
.modal {
  margin-top: 7.5rem;
  height: calc(100% - 7.5rem);
  &.teacher {
    .form-group textarea {
      height: 208px;
    }
  }

  .modal-dialog {
    > div {
      border-radius: 0;
      border: 0;
      padding: 2rem;
    }
    .modal-content {
      border: 1px solid #f4f5f8;
      width: 370px;
      height: 620px;
      padding: 30px 32.5px;
      border-radius: 4px;
      .close-button {
        width: 12px;
        display: flex;
        align-items: center;
        height: 12px;
        justify-content: center;
        position: absolute;
        top: 12px;
        border: none;
        background: transparent;
      }
      &.rtl {
        .close-button {
          left: 12px;
        }
      }
      &.ltr {
        .close-button {
          right: 12px;
        }
      }
    }

    //max-width: 90rem;

    .modal-header {
      border: none;
      font-weight: 500;
      padding: 0 0 20px 0 !important;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      button {
        position: absolute;
        top: 1rem;
        right: 1rem;
        width: 1.6rem;
        height: 1.6rem;
        cursor: pointer;

        svg,
        img {
          width: 1.6rem;
          height: 1.6rem;
          object-fit: contain;
        }
      }

      h4 {
        text-align: center;

        span {
          font-weight: 600;
        }
      }
    }

    .modal-body {
      padding: 0 !important;
      .form {
        width: 100%;
        .form-group {
          margin-bottom: 10px !important;
        }
        .form-group:last-child {
          margin-bottom: 0px !important;
        }
      }

      .g-recaptcha {
        > div {
          margin: auto;
        }
      }
    }

    .modal-footer {
      border: 0;

      .btn-primary {
        margin: 0 1rem;
        min-width: 14rem;
      }
    }
  }

  .validation-modal {
    max-width: unset !important;

    .modal-content {
      height: 300px !important;
      width: 370px;
      border-radius: 4px;
      border: solid 1px rgba(38, 103, 127, 0.22);
      max-width: 90%;
    }
  }
}

@media (max-width: 578px) {
  .teaching {
    .container {
      .teaching-header {
        .logo {
          margin: 0;
          width: 60px;
          height: 60px;
        }

        .inform {
          width: 100% !important;
          justify-content: center;

          .short-desc {
            max-width: 100%;
          }

          h1 {
            text-align: center;
          }

          p {
            text-align: center;
          }
        }
      }
    }
  }

  .modal {
    height: 100%;
    margin-top: 0;
    .modal-dialog {
      height: 100%;
      margin: auto !important;
      .modal-content {
        height: 100%;
        display: flex;
        padding: 30px 18px 30px 17px;
      }
      .modal-body {
        .form {
          width: 100%;
        }
      }

      .modal-footer {
        flex-wrap: wrap;
      }
    }
  }
}
