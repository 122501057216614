/**
@deprecated
 */
// не используйте отсюда ничего.
// colors
$color-blue: var(--color-primary);
$color-dark: #292929;
$color-light: #fefefe;
$color-gray: #bcbcbc;
$color-orange: #f29a21;
$color-white: #ffffff;
// $color-blue-hover: #015795;
$color-blue-hover: var(--color-primary-dark);
$new-background-hover: var(--color-primary-light);
$new-primary-blue: var(--color-primary);
$new-primary-blue-text: var(--color-primary);
$block-box-shadow: 0 2px 9px 0 rgba(233, 233, 233, 0.5);
$content-menu-border-color: #f0f1f5;
$course-category-price-color: #a1a5c0;
$button-gradient-blue: linear-gradient(to bottom, var(--color-secondary), var(--color-primary));
$button-gradient-white: linear-gradient(to bottom, #ffffff, #e6e6e6);
$gradient-404-bkg: linear-gradient(to bottom, var(--color-accent), var(--color-primary));
$new-progress-bar-course-color: #01a22a;
$error-border-input: solid 1px #f9a3b3;
$focus-input-border: solid 1.6px #96c8f2;
$new-pending-course-color-label: #ffb200;
$new-enrolled-course-color-label: #01a22a;
$new-over-course-color-label: var(--color-secondary);
$new-app-course-navigation-bkg: var(--color-primary-50);
$forum-title-color: #292929;
$forum-badge-color: #f2526e;
$popup-input-border: solid 1px rgba(161, 165, 192, 0.4);
$status-pass-complexity-default-color: #e8e9f0;
$status-pass-complexity-red-color: #f2526e;
$status-pass-complexity-yellow-color: #ffbc20;
$status-pass-complexity-green-color: #00c500;
$disable-button-bkg: #d8d9e5;
$new-footer-light-blue-bkg: rgba(18, 132, 227, 0.05);
$active-menu-item-bkg: var(--color-primary-50);
$input-error-color: #f2526e;
$light-gray-border: solid 1px rgba(161, 165, 192, 0.25);
$border-item: solid 1px rgba(151, 151, 151, 0.1);

//box-shadow
$box-shadow-hover: 0 0 0.7rem 2px rgba(0, 0, 0, 0.35);
$box-shadow: 0 1px 2px 0 #c4cbdd;
$new-shadow-block: 0 2px 20px 0 #dee8ff;
//transition
$transition: all 0.5s ease;

$bottom-space: 3rem;

$new-primary-violet-light: #eeeffb;
$new-primary-violet-font-color: #4542a9;
$footer-text-font-color: #13192a;

$selected-sidebar-item-bkg: #f7f7fa;
$selected-sidebar-color-line: rgba(50, 47, 161, 0.8);
$selected-sidebar-width-line: 6px;
$selected-sidebar-font-weight: 6px;
$sidebar-item-title-height: 45px;
$sidebar-item-subtitle-height: 30px;

$dropdown-toggle-font-color: var(--color-primary);
$dropdown-toggle-font-size: 1.5rem;

$search-border-color: rgba(50, 47, 161, 0.29);
$search-input-height: 38px;
$mobile-search-burger-color: #292929;

$header-bkg: #fff;

$old-bkg-dropdown-content: rgba(0, 0, 0, 0.35);
$new-bkg-dropdown-content: #fff;

$old-dropdown-content-font-color: #fff;
$new-dropdown-color-non-active-item: #292929;
$new-dropdown-content-font-color: #4542a9;

$old-dropdown-color-active-item: rgba(0, 0, 0, 0.5);
$new-dropdown-color-active-item: #eeeffb;
