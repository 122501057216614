@import "swiper/swiper";
@import "swiper/swiper-bundle.css";

:root {
  --swiper-theme-color: #000000;
  --swiper-navigation-size: 20px;
}

.swiper {
  width: 100%;
  height: auto;
}

.swiper-slide {
  width: 278px;
}

.swiper-button-next,
.swiper-button-prev {
  top: 70px;
  width: 36px;
  height: 36px;
  display: flex;
  background-color: #fff;
  border: 2px solid #e8eaf5;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 6px 2px #a1a5c07f;
  transition: transform 0.3s ease;
}

.swiper-button-next {
  right: -17px;
}

.swiper-button-prev {
  left: -17px;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  border: none;
  background-color: var(--color-primary);
  color: var(--text-button-color);
}

.swiper-button-next:hover svg path,
.swiper-button-prev:hover svg path {
  fill: var(--text-button-color);
}

.swiper-button-next svg,
.swiper-button-prev svg {
  width: 50%;
  height: 50%;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 16px;
}
