@use "sass:map";
@use "@angular/material" as mat;

@import "scss/index";
@import "scss/custom-swiper";
@import "@libraries/theme";

@include yeda-theme();
:root {
  --text-button-color: #ffffff;
  --category-chip: #a1a5c026;
  --no-image-frist-color: #e8eaf5;
  --no-image-second-color: #ffffff;
  --teacher-card-border: #e8eaf5;
  --teacher-card-shadow: #0a59eb14;
}


body {
}

@media (min-width: 1200px) {
  .container {
    max-width: 1205px !important;
  }
}

.container-loader-block {
  width: 100%;

  &--loading {
    position: relative;
    filter: blur(5px);
  }
}

.vimeo-div > iframe {
  width: 100%;
}

.modal.show .modal-dialog {
  margin: auto;
}

[disabled],
.disabled-and-no-events {
  opacity: 0.5;
  pointer-events: none;
}

body.ltr .cdk-drag-preview {
  direction: ltr;
}

body.rtl .cdk-drag-preview {
  direction: rtl;
}

textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--text-primary);
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px transparent;
}

.text-tiny {
  font-size: 0.7em;
}

.text-small {
  font-size: 0.85em;
}

.text-big {
  font-size: 1.4em;
}

.text-huge {
  font-size: 34px;
}

.admin-header-button {
  width: fit-content;
  margin-inline-end: 26px;
  display: flex;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  color: var(--color-admin-header-button);
  border: none;
  background: transparent;

  &:disabled {
    color: #a1a5c08c;
  }

  &:hover {
    background: var(--category-button-cansel-hover);
  }
}

.footer-text {
  p {
    line-height: 20px;

    span {
      color: #9c9eb0 !important;
    }
  }
}

.cancel-payment-bottom__some-info {
  &__text {
    p {
      line-height: 22px;
      font-size: 16px;
      font-weight: 400;

      span {
        color: #8b8ea4;
      }
    }
  }
}
