@media (min-width: 951px) {
  .mobile-only {
    display: none !important;
  }
}

@media (max-width: 950px) {
  .desktop-only {
    display: none !important;
  }
  .hide-mobile {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .bp-mobile {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .bp-mobile-hide {
    display: none !important;
  }
}
