@import "../var";

.navigation-side {
  position: relative;

  .sidebar-section {
    font-weight: 500;
    justify-content: space-between;
    gap: 10px;

    &__title {
      display: flex;
      line-height: 1.2;
      cursor: pointer;
      align-items: center;
    }
  }

  .collapsing.width {
    width: 0;
    height: 100%;

    transition: width ease-out,
    visibility;
  }

  .btn-double-arrow {
    position: relative;
    cursor: pointer;
    text-align: center;
    width: 30px;
    border: 1px solid var(--color-border-inner);
    background-color: var(--background-primary);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    air-dynamic-icon {
      transition: all 0.4s;

      &.rotate-arrow {
        transform: rotate(180deg);
      }
    }

    svg.fixed {
      transition: all 0.4s;
      position: absolute;
      left: calc(50% - 6px);
      top: 85px;
    }
  }

  .navigation {
    padding: 0;

    .list-group {
      width: 240px;

      cdk-accordion-item {
        font-weight: 300;
        color: var(--text-primary);

        a[data-target="#topicName133"] {
          height: $sidebar-item-title-height;
        }

        a {
          padding: 10px 30px;
          line-height: 1.2;
          position: relative;
          height: auto;
          display: flex;
          align-items: center;
          color: var(--text-primary) !important;

          &.active {
            background-color: var(--color-primary-50);
            border-inline-start: 6px solid $new-primary-blue;
            font-weight: 500;
          }

          & ~ ul li {
            list-style: none;
          }

          & ~ ul > li > div > a {
            padding-right: 45px;
          }

          & ~ ul ul {
            display: block;
          }

          & ~ ul a:before {
            display: none;
          }
        }

        > a {
          position: relative;

          &:hover {
            color: var(--color-primary);
          }

          &:after {
            display: none;
          }

          .list-group-arrow {
            height: 11px;
            width: 11px;
            font-weight: bold;
            margin-top: 1px;
          }
        }

        > a[aria-expanded="true"] {
          background-color: $active-menu-item-bkg;
          font-weight: $selected-sidebar-font-weight;

          .list-group-arrow {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  yeda-course-sidebar {
    min-height: 300px;
  }
  .navigation-side {
    background-color: var(--background-primary);
    z-index: 1;
    max-width: 100%;

    .sidebar-container {
      margin-top: 0px !important;
    }

    .navigation {
      overflow: hidden;
      // width: 100%;
      &.show {
        overflow-y: auto;
      }
    }
  }
}

@media (max-width: 578px) {
  .navigation-side {
    .navigation {
      .list-group {
        width: 100vw;

        div {
          font-size: var(--h5__font-size);
        }
      }
    }

    .btn-double-arrow {
      width: 100vw;
      height: 50px;
      position: absolute;
      top: -50px;
      border: 0;

      &.sticky {
        position: fixed;
        top: var(--header-height);
      }

      &:after,
      &:before {
        content: "";
        height: 1px;
        width: 100%;
        position: absolute;
        background: radial-gradient(circle, var(--color-primary) 0%, rgba(166, 115, 41, 0) 130%);
        left: 0;
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 0;
      }
    }
  }
}
