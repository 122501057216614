@import "../../scss/var";

.banner {
  position: relative;
  z-index: 9;

  .container-fluid {
    background-size: cover;
    background-position: center;
    padding: 80px 0px 80px;

    .desc {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      h1 {
        line-height: 1;
        color: $color-white;
        font-weight: bold;
        text-align: start;
        margin: 0;
        width: 100%;
      }

      p {
        font-weight: 300;
        color: white;
        margin: 30px 0 30px;
        width: 90%;
        text-align: start;
      }

      .btn-primary {
        //width: 32rem;
        font-weight: normal;
        background-image: $button-gradient-blue;
        border-radius: 4px;
      }
    }
  }
}

.all-courses {
  padding: 30px 0 70px;
  position: relative;
  //overflow: hidden;
  flex-grow: 1;

  .white-bg {
    position: absolute;
  }

  .filter {
    margin-bottom: 20px;

    h2 {
      color: $color-dark;
      font-weight: 500;
      margin-bottom: 40px;
    }

    .search-item {
      display: inline-flex;
      margin-bottom: 15px;

      form {
        width: 100%;

        .form-row {
          > div {
            //margin-left: 20px;
          }
        }

        .date {
          // width: 111px;
          height: 35px;
          border-bottom: 1px solid #e1e1e1;
          background-color: $color-white;
          position: relative;

          select {
            box-shadow: none;
            width: 24rem;
            cursor: pointer;
            //line-height: normal;
            height: 100%;
            -webkit-appearance: none;
            -moz-appearance: none;
            -ms-appearance: none;
            appearance: none;
            border-radius: 6px;
            display: block;
            border: none;
            background: #fff;
            color: #9f9f9f;
            //padding: 8px 10px 8px 25px;
            margin: 0;
            line-height: normal;
            border-radius: 2px;
          }

          .form-control {
            display: flex;
            flex-direction: column;
            color: $color-dark;
            justify-content: center;
          }

          .form-control:focus {
            box-shadow: none;
          }
          &.date2 {
            width: 140px;
            height: auto;
            background-color: var(--background-secondary);
            position: relative;
            .form-group {
              margin-bottom: 0;
            }
            &:after {
              content: "";
            }
            .form-control:focus ~ .input-group-append .btn {
              box-shadow: none;
            }
          }
        }

        .date:after {
          content: url("/assets/img/arrow.svg");
          position: absolute;
          z-index: 2;
          left: 8px;
          top: 50%;
          transform: translateY(-50%);
          height: 8px;
          width: 10px;
          pointer-events: none;
        }

        .form-control {
          padding: 5px 10px 5px 27px;
          height: 33px;
          font-weight: 400;
          line-height: 24px;
          box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
          border: none;
          min-width: 100px;
          cursor: pointer;
        }
      }

      span {
        color: $color-dark;
        font-weight: 300;
      }
    }
  }

  .col-2-custom {
    flex: 0 0 20%;
    // max-width: 100%;
    position: relative;
    min-height: 1px;
    padding: 10px;

    .item {
      //padding: 15px;
      background-color: white;
      box-shadow: $box-shadow;
      min-height: 100%;

      &:hover {
        transition: $transition;
        box-shadow: $new-shadow-block;
      }

      .img-container {
        .logo {
          background-color: $color-white;
          height: 40px;
          width: 40px;
          border: 1px solid #d1d1d1;
          border-radius: 2px;
          position: absolute;
          left: 17px;
          bottom: 0;
          transform: translateY(50%);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 5;

          img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
          }
        }

        .img {
          width: 100%;
          position: relative;
          height: 143px;
          max-width: 100%;
          overflow: hidden;

          &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: #026d92;
            opacity: 0.05;
            z-index: 1;
          }

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .desc {
        //margin-top: 20px;
        .short_descr {
          color: #292929 !important;
        }
        .start-data {
          color: #292929 !important;
          padding-top: 5px;
        }

        > span {
          width: 100%;
          display: block;
          color: #a1a5c0;
          font-weight: 400;
          line-height: 22px;

          &.name {
            color: $color-dark;
            font-weight: 600;
            white-space: pre-wrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          &.price {
            color: $new-primary-blue;
            font-weight: 600;
            margin-top: 20px;
            display: flex;
            min-height: 24px;
            align-items: center;

            .old-price {
              color: #a1a5c0;
              position: relative;
              margin-left: 10px;
              display: inline-block;
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }
}

// button datapicker
button.calendar,
button.calendar:active {
  width: 3.8rem;
  height: 33px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAcCAYAAAAEN20fAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAEUSURBVEiJ7ZQxToVAEIY/YCHGxN6XGOIpnpaEsBSeQC9ArZbm9TZ6ADyBNzAhQGGl8Riv4BLAWAgmkpBYkH1b8FWT2WK/zJ8ZJ4qiI6XUI3ANnGKWBnht2/ZBDRK3hgVGNsCd7/ui+JkEIrKtqurLpEWaphd933+IyI3LEIdpCYCiKD6HcuOa/nwOa0ScJEnk0BJg0UTUWJRl6RxCYEzEmomsIlPU3IPW+grIAbquy+q6fluy/28RIBeRMwDXdXMgXLj/B2uimRXpui4D9sBeRLKl+1N+L+t6RwbWrZliTTTr1oxYtzVWiTQAcRxvTX+eJMnlUDaO1vpZRO5NS0x48sIwfPc87xg4B04MCzQi8hIEwe4bl1DnFMCN2zsAAAAASUVORK5CYII=") !important;
  background-repeat: no-repeat;
  background-size: 23px;
  background-position: center;
  padding: 5px 10px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.35);
  border: none;
}

.input-group-append .btn,
.input-group-prepend .btn {
  position: relative;
  z-index: 2;
}
.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.form-group input,
.form-group textarea {
  background-color: var(--input__background);
}

ngb-datepicker.dropdown-menu {
  left: 0 !important;
  .form-control {
    color: #9f9f9f;
  }
}
input:-internal-autofill-previewed,
input:-internal-autofill-selected,
textarea:-internal-autofill-previewed,
textarea:-internal-autofill-selected,
select:-internal-autofill-previewed,
select:-internal-autofill-selected {
  background-color: #fff !important;
  background-image: none !important;
  color: #9f9f9f !important;
}
.all-courses {
  .filter {
    .search-item {
      ngb-datepicker-navigation {
        flex-direction: row-reverse;
        span {
          color: inherit;
        }
        ngb-datepicker-navigation-select select.custom-select {
          flex: 1 1 auto;
          padding: 0 0.5rem;
          height: 1.85rem;
          flex-direction: row-reverse;
        }
        select.custom-select {
          height: auto;
          display: inline-block;
          width: 100%;
          height: calc(2.25rem + 2px);
          padding: 0.375rem 0.75rem 0.375rem 1.75rem;
          font-weight: 400;
          line-height: 1.5;
          color: #495057;
          vertical-align: middle;
          background-color: #fff;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
          appearance: none;
          background: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'><path fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>")
            left 0.5rem center/8px 10px no-repeat #fff;
          &:focus {
            box-shadow: 0 0 0 0.1rem rgba(128, 189, 255, 0.5);
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .all-courses {
    .col-2-custom {
      .item {
        .img-container .img {
          height: 142px;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .all-courses {
    .col-2-custom {
      flex: 0 0 25%;
      max-width: 25%;
      position: relative;
      min-height: 1px;
      padding: 10px;
      .item {
        .img-container .img {
          height: 130px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .all-courses {
    .col-2-custom {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
      position: relative;
      min-height: 1px;
      padding: 10px;
    }
  }
}

@media (max-width: 578px) {
  .banner {
    .container-fluid {
      //background-image: url('assets/img/bg-mobile.jpg');
      padding: 80px 15px 68px 15px;
      .container {
        padding-right: 0;
        padding-left: 0;
        align-items: center;
        .desc-title,
        .description-text {
          text-align: center;
        }
      }
    }
  }

  .all-courses {
    .filter {
      margin-bottom: 20px;

      .search-item {
        .date {
          select {
            width: 100% !important;
            min-width: 24rem;
          }
        }
        .search-item-text {
          width: 100%;
          margin-bottom: 10px;
        }
      }
    }

    .col-2-custom {
      flex: 0 0 50%;
      max-width: 50%;
      position: relative;
      min-height: 1px;
      padding: 10px;
      .item {
        .img-container {
          .img {
            max-width: 100%;
            height: 180px;
            img {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .all-courses {
    .col-2-custom {
      flex: 0 0 100%;
      max-width: 100%;
      position: relative;
      min-height: 1px;
      padding: 0 17px 15px 16px;
      .item {
        .img-container {
          .img {
            img {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
