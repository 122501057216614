@import "../../scss/var";


.survey {
  .navigation {
    padding: 20px 0;
  }

  .container {
    position: relative;
    padding: 0 20px;

    > .row {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  .survey-body {
    padding: 20px 0;

    .title {
      line-height: 1;
      padding: 0 20px;
      font-weight: 400;
      color: var(--text-primary);
    }

    .description {
      color: var(--text-primary);
      background-color: var(--color-primary-50);
      font-weight: 300;
      padding: 20px;
      margin: 15px 0 25px;
    }

    .questions-list {
      padding: 0 20px;
      width: 100%;

      .question {
        line-height: 17.9px;
        color: var(--text-primary);
      }

      .ansver {
        padding: 15px 0 25px;
        // display: inline-block;
        textarea {
          width: 100%;
          height: 90px;
          border: 1px solid var(--color-border-inner);
          background-color: var(--input__background);
          color: var(--text-primary);
          padding: 15px;
          font-weight: 400;

          ::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: var(--text-secondary);
          }

          ::-moz-placeholder {
            /* Firefox 19+ */
            color: var(--text-secondary);
          }

          :-ms-input-placeholder {
            /* IE 10+ */
            color: var(--text-secondary);
          }

          :-moz-placeholder {
            /* Firefox 18- */
            color: var(--text-secondary);
          }
        }

        .select-control {
          width: auto;
          display: inline-block;
        }

        .input-error-message {
          width: calc(100% - 110px);
          color: red;
          margin-top: 3px;
          display: block;
        }
      }

      .marks {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // flex-wrap: wrap;
        .value {
          margin: 0 30px;
          display: flex;

          label {
            flex-direction: column;

            .label {
              margin: auto;
            }
          }
        }

        div:first-child,
        div:last-child {
          color: #191919;
          font-weight: 400;

          p {
            text-align: center;
          }
        }
      }

      .btn-primary {
        width: 120px;
        text-transform: capitalize;
      }

      .closed {
        margin-inline-start: 20px;
      }

      .select-control {
        &:after {
          content: url("/assets/dynamic-icons/default/arrow-down.svg");
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translateY(-50%);
          pointer-events: none;
          z-index: 0;
          color: var(--text-primary);
        }

        .form-control {
          width: 100%;
          height: 48px;
          border-radius: 0;
          border: 1px solid var(--color-border-inner);
          background-color: var(--input__background);
          -webkit-appearance: none;
          -moz-appearance: none;
          -ms-appearance: none;
          padding: 0 35px 0 15px;
          color: var(--text-primary);

          &:focus {
            box-shadow: none;
          }
        }
      }
    }
  }
}

yeda-course-survey {
  display: flex;
  flex: 1 1 100%;

  .left-content {
    width: 100%;
  }
}

.form-check-survey {
  margin: auto;
  margin-left: 0;
  margin-bottom: 16px;

  select * {
    background-color: red;
  }

  label {
    margin-bottom: 0;
    display: flex;
    justify-content: flex-end;
    flex-direction: row-reverse;
    align-items: center;

    .label {
      margin-inline-start: 10px;
      color: var(--text-primary);
    }

    .checkbox {
      display: none;
    }

    .radio-own {
      cursor: pointer;
      border-radius: 50%;
      padding: 10px;
      position: relative;
      width: 18px;
      height: 18px;
      border: 1px solid #787878;
      background-color: var(--background-secondary);
    }

    .checkbox-own {
      cursor: pointer;
      border-radius: 4px;
      padding: 7px;
      position: relative;
      width: 16px;
      height: 16px;
      border: 1px solid #787878;
      background-color: var(--background-secondary);
    }

    .checkbox:checked ~ .radio-own,
    .checkbox:checked ~ .checkbox-own {
      border: 1px solid #0a0a0a;
    }

    .checkbox:checked ~ .label {
      font-weight: 500;
    }

    .checkbox:checked ~ .label {
      font-weight: 500;
    }

    // .checkbox:checked~.label.true.help {
    //     font-weight: 700;
    //     color: #176505;
    // }

    .label.true.help {
      font-weight: 500;
      color: #176505;
    }

    .label.false.help {
      color: #e10b0b;
      font-weight: 500;
      text-decoration: line-through;
    }

    .checkbox:checked ~ .label.false.help {
      font-weight: 500;
      color: #176505;
      text-decoration: none;
    }

    .checkbox:checked ~ .radio-own::before {
      content: "";
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 4px;
      right: 4px;
      bottom: 4px;
      left: 4px;
      background: var(--color-primary);
    }

    .checkbox:checked ~ .checkbox-own::before {
      content: "";
      display: block;
      position: absolute;
      right: -2px;
      bottom: 6px;
      border-bottom: 3px solid var(--color-primary);
      width: 15px;
      border-left: 3px solid var(--color-primary);
      height: 7.5px;
      transform: rotate(-50deg);
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}



@media (max-width: 768px) {
  .survey {
    .survey-body {
      .questions-list {
        .marks {
          flex-wrap: wrap;

          .value {
            margin: 0 25px;
          }

          > div {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: flex-start;
          }

          > div:first-child,
          > div:last-child {
            margin: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 578px) {
  .survey {
    .survey-body {
      .questions-list {
        .marks {
          .value {
            margin: 0 15px;
          }

          > div {
            display: flex;
            align-items: center;
            width: 100%;
            justify-content: flex-start;
          }

          > div:first-child,
          > div:last-child {
            margin: 10px;
          }
        }
      }
    }
  }
}
