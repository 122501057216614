/**
Нам нужно переделать структуру стилей
1. В папки common, content - ничего нового не добавляем, стараемся по возможности удалять оттуда стили
2. Никаких классов вида .cd-2 mr-2 mr-23 итд, классы должны иметь логическое название
3. Не используем bootstrap, если видишь в коде html с бутстрап - заменяй на компоненты из папки shared, что-бы в конечном итоге мы могли удалить импорт бутстрап
4. стили из этого файла тоже нужно перенести или удалить, тут только импорты
 */

@import "bootstrap/scss/bootstrap";
@import "@airy-ui/cdk/styles/initial";
@import "var.scss";
@import "../app/shared/modules/air/air-modules/loaders/index";
@import "@airy-ui/cdk/styles";
@import "@airy-ui/layout/styles";
@import "@airy-ui/typography";
@import "@airy-ui/controls";
@import "../app/shared/modules/air/air-modules/ngxs-data-draft/default-styles";
@import "admin";
@import "wl";
@import "dark-theme";
@import "size";

:root {
  @include air-colors(
    $background: #fff,
    $background-secondary: #fff,
    $text: #000,
    $text-secondary: #a1a5c0,
    $borders: #e8eaf5,
    $primary: (
      main: #0a59eb,
      dark: #052c76,
      light: #c4d8fd,
      shades: (
        50: #ebf2fe,
      ),
    ),
    $accent: (
      main: #079ded,
      dark: #044e77,
      light: #c4e9fd,
      shades: (
        50: #ebf2fe,
      ),
    ),
    $success: (
      main: #43b145,
      dark: #225923,
      light: #d3eed3,
      shades: (
        50: #f0f9f0,
      ),
    ),
    $warn: (
      main: #ffbc20,
      dark: #e8a201,
      light: #fff4c2,
      shades: (
        50: #fff6eb,
      ),
    ),
    $danger: (
      main: #dc1833,
      dark: #b8142a,
      light: #f9c8ce,
      shades: (
        50: #fdedef,
      ),
    ),
    $disabled: (
      main: #a1a5c0,
      dark: #74778a,
      light: #1b1b1e,
    )
  );

  --page__flex-direction: column;
  --border-radius: 6px;

  @include air-grid(74px, 28px);
  @include air-default-button-sizes;
  @include air-default-indents;
}

@import "./common/index.scss";
@import "./content/index.scss";
@import "./components/index.scss";
@import "vars/index";
@import "layout/index";

body {
  overflow-x: hidden;
  text-align: inherit !important;
  color: var(--text-primary);
}

/**
@deprecated
 */
.pointer {
  cursor: pointer;
}

.modal-dialog {
  height: 100vh;
  margin: 0;
  display: flex;
  width: 100%;
  max-width: none;
  align-items: center;
  justify-content: center;
}

.modal-content {
  max-height: 95vh;
  overflow: auto;
}

.modal {
  height: 100%;
  margin: 0;
}

/* Validation forms */
air-form-field {
  .ng-untouched {
    & + control-error {
      display: none !important;
    }
  }
}

.ask-phone-panel,
.welcome-panel,
.lesson-panel,
.learned-lesson-panel {
  air-modal-wrapper {
    overscroll-behavior: none;
    width: 700px;

    @media screen and (max-width: 599px) {
      padding: var(--indent-m) !important;
      h2,
      h4 {
        word-break: break-word;
      }
      h2 {
        padding-top: var(--indent-m);
      }
    }
  }
}
