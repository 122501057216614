:root {
  --button-font-weight: 500;
  --button-font-size: 14px;

  --lg-button-font-size: 16px;
  --lg-button-height: 48px;

  --md-button-height: 34px;
  --button-focused-outline: none;
  --button__transition: 200ms ease-in-out;
}

.air-button {
  &.clear {
    --button-font-weight: 400;
  }
}
