@use "@angular/material" as mat;
@use "angular-material-css-vars" as mat-css-vars;
@use "sass:map";
@import "themes";
@import "theme-init";
@import "fonts-init";
@import "css-vars-init";
@import "global-init";
@mixin yeda-theme() {
  @include theme-init();
  @include css-vars-init();
  @include fonts-init();
  @include global-init();

  .tertiary {
    @include mat.datepicker-color($theme-light, $color-variant: tertiary);
  }

  .small-form-field {
    @include mat.form-field-density($theme-low-density);
  }

  .large-button {
    @include mat.button-density($theme-high-density);
  }

  .notification-link-wrapper {
    a {
      position: relative;
      z-index: 2;

      &:not(:hover) {
        color: var(--color-primary);
      }
    }
  }
}
