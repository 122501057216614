@use "@angular/material" as mat;
@import "themes";
@mixin css-vars-init() {
  body {
    --yeda-toolbar-padding: 16px;
    --yeda-background-primary: #fff;
    --yeda-background-secondary: #f6f9ff;
    --yeda-background-tertiaty: #fff;
    --yeda-text-primary: #1b1b1e;
    --yeda-text-secondary: #a1a5c0;
    --yeda-border-radius: 4px;
    --yeda-border-color: #a1a5c033;
    --yeda-color-success-rgb: 1, 162, 42;
    --yeda-color-success: rgba(var(--yeda-color-success-rgb), 1);
    --yeda-color-warn: rgba(208, 30, 37, 1);
    --yeda-text-size-primary: 16px;
    --yeda-text-size-secondary: 14px;
    --yeda-text-size-tertiary: 12px;
    min-height: 100vh;
    color: var(--yeda-text-primary);
    --palette-background-background: var(--yeda-background-primary);
    --surface: #{mat.get-theme-color($theme-light, surface)};
    --surface-dim: #{mat.get-theme-color($theme-light, surface-dim)};
    --surface-bright: #{mat.get-theme-color($theme-light, surface-bright)};
  }
}
