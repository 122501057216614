@mixin fonts-init() {
  @import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400&display=swap");
  * {
    font-family: "Rubik", sans-serif;
  }
  @import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20,400,0,0");
  .material-icons {
    font-family: "Material Symbols Outlined";
    line-height: 1;
    font-size: 24px;
  }
}
