@import "../var";

.no-messages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 70px 30px;

  p {
    color: var(--text-secondary);
    text-align: center;
    margin-bottom: 25px;
  }
}

.messages {
  .container {
    position: relative;
  }

  .messages-item {
    padding: 20px 25px 20px 20px;
    background-color: var(--background-secondary);
    margin-bottom: 2rem;
    border: $border-item;
    box-shadow: var(--item-box-shadow);
    border-radius: 6px;
    .messages-header {
      border-bottom: $light-gray-border;
      width: 100%;

      p {
        font-weight: 500;
      }

      .date {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: var(--text-secondary);
      }
    }
  }

  .messages-item:last-child {
    margin-bottom: 20px;
  }

  .messages-body {
    .title {
      font-weight: 600;
      color: var(--text-primary);
    }

    .text {
      max-width: 60%;
      color: var(--text-primary);

      p {
        margin-bottom: 1rem;
      }

      & :last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 992px) {
  .messages {
    .messages-body {
      .text {
        max-width: 100%;
      }
    }
  }
}
