.page-tabs {
  margin-bottom: 32px;
  position: relative;

  &:before {
    content: "";
    height: 2px;
    background-color: var(--input__background);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
