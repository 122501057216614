@import "../../scss/var";

.teachers {
  .container {
    padding-top: 2rem;
    padding-bottom: 2rem;
    position: relative;

    .teacher-content {
      justify-content: center;
      display: flex;

      //&:last-of-type {
      //    .teacher-item {
      //        margin-bottom: 0;
      //    }
      //}

      .teacher-item {
        cursor: pointer;
        position: relative;
        width: 100%;
        max-width: 65rem;
        background: var(--background-secondary);
        box-shadow: 0 1px 2px 0 var(--item-box-shadow);
        transition: $transition;
        border-radius: var(--border-radius);
        margin-bottom: 30px;
        padding: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: right;
        flex-wrap: wrap;

        &:hover {
          box-shadow: 0 2px 20px 0 var(--item-box-shadow-hover);
        }

        .logo {
          width: 12.5rem;
          height: 12.5rem;
          //margin-left: 1.5rem;
          display: flex;
          flex-wrap: wrap;

          img {
            border-radius: 50%;
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }

        .inform {
          //width: calc(100% - 14rem);
          display: flex;
          flex-wrap: wrap;

          .positions {
            width: 100%;
            color: var(--text-secondary);
            text-align: center;
          }
          .short-desc {
            color: var(--text-primary);
            font-weight: 300;
            margin: 10px 0 20px;
            width: 100%;
            p {
              text-align: center;
              color: var(--text-primary);
            }
          }

          a.title-name {
            color: var(--text-primary);
            font-weight: 600;
            line-height: 1;
            cursor: pointer;
            transition: $transition;
            width: 100%;
            text-align: center;
            margin: 20px 0 10px;
          }

          a.read-more {
            font-weight: 400;
            color: var(--text-primary);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 12rem;
            height: 3rem;
            transition: $transition;
            border: solid 1px $color-blue;
            &:hover {
              background-color: $color-blue;
              color: $color-white;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .teachers {
    margin-top: 20px;
    .container {
      .teacher-block {
        max-width: 100%;
        flex: none;
      }
    }
  }
}

@media (max-width: 450px) {
  .teachers {
    .container {
      .teacher-block {
        max-width: 100%;
        flex: none;
      }
      .teacher-content {
        .teacher-item {
          justify-content: center;

          .logo {
            margin-bottom: 1.5rem;
            margin-left: 0;
          }

          .inform {
            width: 100%;
            justify-content: center;
            .positions {
              text-align: center;
            }
            .short-desc {
              p {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
